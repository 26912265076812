import { SequenceUnit } from './types'
import { Category, Sound } from './constants'

export const sequenceUnits: readonly SequenceUnit[] = [
  {
    category: Category.DR,
    id: 'a-1',
    frameLength: 12,
    spriteSheetLength: 3,
    sound: Sound.DR_1,
  },
  {
    category: Category.DR,
    id: 'a-2',
    frameLength: 19,
    spriteSheetLength: 3,
    sound: Sound.DR_2,
  },
  {
    category: Category.DR,
    id: 'a-3',
    frameLength: 37,
    spriteSheetLength: 5,
    sound: Sound.DR_3,
  },
  {
    category: Category.DR,
    id: 'ab-1',
    frameLength: 18,
    spriteSheetLength: 7,
    sound: Sound.DR_4,
  },
  {
    category: Category.DR,
    id: 'b-1',
    frameLength: 15,
    spriteSheetLength: 4,
    sound: Sound.DR_5,
  },
  {
    category: Category.DR,
    id: 'b-2',
    frameLength: 22,
    spriteSheetLength: 3,
    sound: Sound.DR_6,
  },
  {
    category: Category.DR,
    id: 'b-3',
    frameLength: 23,
    spriteSheetLength: 6,
    sound: Sound.DR_7,
  },
  {
    category: Category.DR,
    id: 'b-4',
    frameLength: 1,
    spriteSheetLength: 1,
    sound: Sound.DR_8,
  },
  {
    category: Category.DR,
    id: 'b-5',
    frameLength: 23,
    spriteSheetLength: 8,
    sound: Sound.DR_9,
  },
  {
    category: Category.DR,
    id: 'bc-1',
    frameLength: 16,
    spriteSheetLength: 4,
    sound: Sound.DR_10,
  },
  {
    category: Category.DR,
    id: 'c-1',
    frameLength: 14,
    spriteSheetLength: 6,
    sound: Sound.DR_11,
  },
  {
    category: Category.DR,
    id: 'c-2',
    frameLength: 21,
    spriteSheetLength: 10,
    sound: Sound.DR_12,
  },
  {
    category: Category.BS,
    id: 'c-3',
    frameLength: 12,
    spriteSheetLength: 7,
    sound: Sound.BS_1,
  },
  {
    category: Category.BS,
    id: 'c-4',
    frameLength: 56,
    spriteSheetLength: 18,
    sound: Sound.BS_2,
  },
  {
    category: Category.BS,
    id: 'c-5',
    frameLength: 26,
    spriteSheetLength: 12,
    sound: Sound.BS_3,
  },
  {
    category: Category.BS,
    id: 'cd-1',
    frameLength: 18,
    spriteSheetLength: 4,
    sound: Sound.BS_4,
  },
  {
    category: Category.BS,
    id: 'd-1',
    frameLength: 10,
    spriteSheetLength: 3,
    sound: Sound.BS_5,
  },
  {
    category: Category.BS,
    id: 'd-2',
    frameLength: 14,
    spriteSheetLength: 7,
    sound: Sound.BS_6,
  },
  {
    category: Category.BS,
    id: 'd-3',
    frameLength: 20,
    spriteSheetLength: 4,
    sound: Sound.BS_7,
  },
  {
    category: Category.BS,
    id: 'd-4',
    frameLength: 25,
    spriteSheetLength: 8,
    sound: Sound.BS_8,
  },
  {
    category: Category.BS,
    id: 'de-1',
    frameLength: 15,
    spriteSheetLength: 7,
    sound: Sound.BS_9,
  },
  {
    category: Category.BS,
    id: 'e-1',
    frameLength: 10,
    spriteSheetLength: 2,
    sound: Sound.BS_10,
  },
  {
    category: Category.BS,
    id: 'e-2',
    frameLength: 20,
    spriteSheetLength: 13,
    sound: Sound.BS_11,
  },
  {
    category: Category.BS,
    id: 'e-3',
    frameLength: 20,
    spriteSheetLength: 15,
    sound: Sound.BS_12,
  },
  {
    category: Category.MEL,
    id: 'ef-1',
    frameLength: 13,
    spriteSheetLength: 5,
    sound: Sound.MEL_1,
  },
  {
    category: Category.MEL,
    id: 'eg-1',
    frameLength: 28,
    spriteSheetLength: 6,
    sound: Sound.MEL_2,
  },
  {
    category: Category.MEL,
    id: 'eg-2',
    frameLength: 23,
    spriteSheetLength: 11,
    sound: Sound.MEL_3,
  },
  {
    category: Category.MEL,
    id: 'eg-3',
    frameLength: 41,
    spriteSheetLength: 5,
    sound: Sound.MEL_4,
  },
  {
    category: Category.MEL,
    id: 'eg-4',
    frameLength: 45,
    spriteSheetLength: 8,
    sound: Sound.MEL_5,
  },
  {
    category: Category.MEL,
    id: 'f-1',
    frameLength: 12,
    spriteSheetLength: 2,
    sound: Sound.MEL_6,
  },
  {
    category: Category.MEL,
    id: 'f-2',
    frameLength: 29,
    spriteSheetLength: 11,
    sound: Sound.MEL_7,
  },
  {
    category: Category.MEL,
    id: 'f-3',
    frameLength: 60,
    spriteSheetLength: 24,
    sound: Sound.MEL_8,
  },
  {
    category: Category.MEL,
    id: 'f-4',
    frameLength: 43,
    spriteSheetLength: 17,
    sound: Sound.MEL_9,
  },
  {
    category: Category.MEL,
    id: 'f-5',
    frameLength: 32,
    spriteSheetLength: 3,
    sound: Sound.MEL_10,
  },
  {
    category: Category.MEL,
    id: 'f-6',
    frameLength: 32,
    spriteSheetLength: 11,
    sound: Sound.MEL_11,
  },
  {
    category: Category.MEL,
    id: 'fg-1',
    frameLength: 19,
    spriteSheetLength: 4,
    sound: Sound.MEL_12,
  },
  {
    category: Category.FX,
    id: 'g-1',
    frameLength: 10,
    spriteSheetLength: 3,
    sound: Sound.FX_1,
  },
  {
    category: Category.FX,
    id: 'g-2',
    frameLength: 56,
    spriteSheetLength: 6,
    sound: Sound.FX_2,
  },
  {
    category: Category.FX,
    id: 'g-3',
    frameLength: 27,
    spriteSheetLength: 1,
    sound: Sound.FX_3,
  },
  {
    category: Category.FX,
    id: 'g-4',
    frameLength: 25,
    spriteSheetLength: 3,
    sound: Sound.FX_4,
  },
  {
    category: Category.FX,
    id: 'gj-1',
    frameLength: 13,
    spriteSheetLength: 6,
    sound: Sound.FX_5,
  },
  {
    category: Category.FX,
    id: 'h-1',
    frameLength: 10,
    spriteSheetLength: 5,
    sound: Sound.FX_6,
  },
  {
    category: Category.FX,
    id: 'i-1',
    frameLength: 6,
    spriteSheetLength: 2,
    sound: Sound.FX_7,
  },
  {
    category: Category.FX,
    id: 'i-2',
    frameLength: 61,
    spriteSheetLength: 1,
    sound: Sound.FX_8,
  },
  {
    category: Category.FX,
    id: 'j-1',
    frameLength: 10,
    spriteSheetLength: 3,
    sound: Sound.FX_9,
  },
  {
    category: Category.FX,
    id: 'j-2',
    frameLength: 49,
    spriteSheetLength: 6,
    sound: Sound.FX_10,
  },
  {
    category: Category.FX,
    id: 'j-3',
    frameLength: 59,
    spriteSheetLength: 8,
    sound: Sound.FX_11,
  },
  {
    category: Category.FX,
    id: 'j-4',
    frameLength: 15,
    spriteSheetLength: 5,
    sound: Sound.FX_12,
  },
  {
    category: Category.AMBIENT,
    id: 'j-5',
    frameLength: 15,
    spriteSheetLength: 4,
    sound: Sound.AMBIENT_1,
  },
  {
    category: Category.AMBIENT,
    id: 'j-6',
    frameLength: 26,
    spriteSheetLength: 3,
    sound: Sound.AMBIENT_2,
  },
  {
    category: Category.AMBIENT,
    id: 'j-7',
    frameLength: 26,
    spriteSheetLength: 21,
    sound: Sound.AMBIENT_3,
  },
  {
    category: Category.AMBIENT,
    id: 'jl-1',
    frameLength: 14,
    spriteSheetLength: 7,
    sound: Sound.AMBIENT_4,
  },
  {
    category: Category.AMBIENT,
    id: 'k-1',
    frameLength: 12,
    spriteSheetLength: 5,
    sound: Sound.AMBIENT_5,
  },
  {
    category: Category.AMBIENT,
    id: 'l-1',
    frameLength: 13,
    spriteSheetLength: 2,
    sound: Sound.AMBIENT_6,
  },
  {
    category: Category.AMBIENT,
    id: 'l-2',
    frameLength: 40,
    spriteSheetLength: 8,
    sound: Sound.AMBIENT_7,
  },
  {
    category: Category.AMBIENT,
    id: 'l-3',
    frameLength: 40,
    spriteSheetLength: 8,
    sound: Sound.AMBIENT_8,
  },
  {
    category: Category.AMBIENT,
    id: 'l-4',
    frameLength: 41,
    spriteSheetLength: 11,
    sound: Sound.AMBIENT_9,
  },
  {
    category: Category.AMBIENT,
    id: 'l-5',
    frameLength: 17,
    spriteSheetLength: 8,
    sound: Sound.AMBIENT_10,
  },
  {
    category: Category.AMBIENT,
    id: 'l-6',
    frameLength: 14,
    spriteSheetLength: 2,
    sound: Sound.AMBIENT_11,
  },
  {
    category: Category.AMBIENT,
    id: 'l-7',
    frameLength: 36,
    spriteSheetLength: 12,
    sound: Sound.AMBIENT_12,
  },
  {
    category: Category.TRADITIONAL,
    id: 'l-8',
    frameLength: 102,
    spriteSheetLength: 17,
    sound: Sound.TRADITIONAL_1,
  },
  {
    category: Category.TRADITIONAL,
    id: 'l-9',
    frameLength: 53,
    spriteSheetLength: 21,
    sound: Sound.TRADITIONAL_2,
  },
  {
    category: Category.TRADITIONAL,
    id: 'lm-1',
    frameLength: 20,
    spriteSheetLength: 12,
    sound: Sound.TRADITIONAL_3,
  },
  {
    category: Category.TRADITIONAL,
    id: 'm-1',
    frameLength: 12,
    spriteSheetLength: 3,
    sound: Sound.TRADITIONAL_4,
  },
  {
    category: Category.TRADITIONAL,
    id: 'm-2',
    frameLength: 23,
    spriteSheetLength: 4,
    sound: Sound.TRADITIONAL_5,
  },
  {
    category: Category.TRADITIONAL,
    id: 'm-3',
    frameLength: 23,
    spriteSheetLength: 9,
    sound: Sound.TRADITIONAL_6,
  },
  {
    category: Category.TRADITIONAL,
    id: 'n-1',
    frameLength: 14,
    spriteSheetLength: 2,
    sound: Sound.TRADITIONAL_7,
  },
  {
    category: Category.TRADITIONAL,
    id: 'n-2',
    frameLength: 28,
    spriteSheetLength: 7,
    sound: Sound.TRADITIONAL_8,
  },
  {
    category: Category.TRADITIONAL,
    id: 'n-3',
    frameLength: 28,
    spriteSheetLength: 3,
    sound: Sound.TRADITIONAL_9,
  },
  {
    category: Category.TRADITIONAL,
    id: 'n-4',
    frameLength: 40,
    spriteSheetLength: 15,
    sound: Sound.TRADITIONAL_10,
  },
  {
    category: Category.TRADITIONAL,
    id: 'o-1',
    frameLength: 11,
    spriteSheetLength: 5,
    sound: Sound.TRADITIONAL_11,
  },
  {
    category: Category.TRADITIONAL,
    id: 'p-1',
    frameLength: 14,
    spriteSheetLength: 2,
    sound: Sound.TRADITIONAL_12,
  },
  {
    category: Category.TRADITIONAL,
    id: 'q-1',
    frameLength: 12,
    spriteSheetLength: 3,
    sound: Sound.TRADITIONAL_13,
  },
]

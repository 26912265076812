import clsx from 'clsx'
import React from 'react'
import styles from './MuteButton.module.scss'

interface MuteButtonProp {
  active: boolean
  onClick: () => void
}

const MuteButton: React.FC<MuteButtonProp> = ({ active, onClick }) => {
  const fillColor = active ? '#70FF24' : '#bfffa1'

  return (
    <button
      className={clsx([styles.MuteButton, { [styles.active]: active }])}
      onClick={onClick}
    >
      <div className={styles.bg}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.35 36.95">
          <path d="M80.73,10.64c-.1-1.7-.3-3.3-.4-5s-1-3.4-2.9-3.9a29.22,29.22,0,0,0-5.1-.4L67.13,1a410.55,410.55,0,0,0-41.5-.8c-3.8.2-7.7.4-11.5.8s-7.6,1-11.4,1.2a2.28,2.28,0,0,0-1.12.39,2.17,2.17,0,0,0-1.38,2c-.6,9.8.1,19.7.7,29.5a2.8,2.8,0,0,0,2.8,2.8c18.9-.1,37.8-.1,56.7-1,5.4-.3,10.9-.6,16.3-1A3.21,3.21,0,0,0,79,33.84a4.21,4.21,0,0,0,.83-.3,2.91,2.91,0,0,0,1.4-2.4A175.41,175.41,0,0,0,80.73,10.64Zm-5.09,17.8a3,3,0,0,0-1.14.86C56.34,30.65,38.08,31,19.83,31.14c-4.51.08-9,.09-13.46.09C6,23.18,5.74,15.08,4.9,7a21.75,21.75,0,0,0,4.33-.18c2.4-.3,4.9-.5,7.3-.7,4.8-.4,9.6-.6,14.5-.7,9.7-.2,19.5.2,29.2.7,2.8.2,5.6.3,8.3.5l3.9.3c.7,0,1.5.1,2.2.1h.22c0,.4.08.8.08,1.2.1,1.1.2,2.3.3,3.4l.3,6.3C75.69,21.44,75.66,24.94,75.64,28.44Z" />
        </svg>
      </div>
      <div className={styles.fore}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.35 36.95">
          <path
            d="M75.23,11.64c-.1-1.1-.2-2.3-.3-3.4,0-.4-.06-.8-.08-1.2h-.22c-.7,0-1.5-.1-2.2-.1l-3.9-.3c-2.7-.2-5.5-.3-8.3-.5-9.7-.5-19.5-.9-29.2-.7-4.9.1-9.7.3-14.5.7-2.4.2-4.9.4-7.3.7A21.75,21.75,0,0,1,4.9,7c.84,8.06,1,16.16,1.47,24.21,4.49,0,9,0,13.46-.09C38.08,31,56.34,30.65,74.5,29.3a3,3,0,0,1,1.14-.86c0-3.5,0-7-.11-10.5Z"
            style={{ fill: fillColor }}
          />
          <path d="M80.73,10.64c-.1-1.7-.3-3.3-.4-5s-1-3.4-2.9-3.9a29.22,29.22,0,0,0-5.1-.4L67.13,1a410.55,410.55,0,0,0-41.5-.8c-3.8.2-7.7.4-11.5.8s-7.6,1-11.4,1.2a2.28,2.28,0,0,0-1.12.39,2.17,2.17,0,0,0-1.38,2c-.6,9.8.1,19.7.7,29.5a2.8,2.8,0,0,0,2.8,2.8c18.9-.1,37.8-.1,56.7-1,5.4-.3,10.9-.6,16.3-1A3.21,3.21,0,0,0,79,33.84a4.21,4.21,0,0,0,.83-.3,2.91,2.91,0,0,0,1.4-2.4A175.41,175.41,0,0,0,80.73,10.64Zm-5.09,17.8a3,3,0,0,0-1.14.86C56.34,30.65,38.08,31,19.83,31.14c-4.51.08-9,.09-13.46.09C6,23.18,5.74,15.08,4.9,7a21.75,21.75,0,0,0,4.33-.18c2.4-.3,4.9-.5,7.3-.7,4.8-.4,9.6-.6,14.5-.7,9.7-.2,19.5.2,29.2.7,2.8.2,5.6.3,8.3.5l3.9.3c.7,0,1.5.1,2.2.1h.22c0,.4.08.8.08,1.2.1,1.1.2,2.3.3,3.4l.3,6.3C75.69,21.44,75.66,24.94,75.64,28.44Z" />
        </svg>
      </div>
      <div className={styles.label}>MUTE</div>
    </button>
  )
}

export default MuteButton

import React from 'react'

import styles from './UndoButton.module.scss'

interface UndoButtonProps {
  onClick: () => void
}

const UndoButton: React.FC<UndoButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.UndoButton} onClick={onClick}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 78.14 75.91"
      >
        <g>
          <path
            fill="#ffffff"
            d="M69.32,51.38c-0.37-2.46-0.65-4.7-1.69-6.7c-0.6-3.88-2.16-7.71-5.05-10.12c-1.82-2.77-4.62-5.27-7.74-6.02
		c-2.29-1.77-5.15-2.81-7.93-2.69c-0.26-0.17-0.53-0.34-0.8-0.49c-2.04-1.12-3.84-1.49-5.41-1.33c-1.83-0.47-3.84-0.26-5.92,0.92
		c-0.22-0.18-0.44-0.35-0.67-0.52c-0.97-0.7-2.01-0.94-3.02-0.84c0.02-2.36-0.01-4.71-0.22-7.02c-0.65-6.96-10.7-7.18-11,0
		c-0.1,2.38-3.94,5.9-5.2,7.85c-1.73,2.68-3.24,5.47-4.63,8.35c-1.53,3.15,0.05,6.12,2.4,7.52c0.24,0.39,0.48,0.79,0.71,1.19
		c2.66,4.54,5.33,9.03,8.52,13.23c2.98,3.93,10.53,3.26,10.25-2.78c-0.06-1.23-0.11-2.46-0.17-3.69c1.47-0.76,2.37-2.01,2.94-3.53
		c1.61,0.25,3.24-0.25,4.64-1.24c0.47,0.09,0.98,0.12,1.51,0.11c0.06,0.05,0.11,0.11,0.17,0.16c2.14,1.78,4.72,1.9,6.94,0.95
		c1.09,1.14,2.47,1.95,3.93,2.34c1.08,1.72,2.61,3.08,4.46,3.66c0.25,0.9,0.66,1.79,1.26,2.68c0.35,0.52,0.75,0.95,1.17,1.31
		c0.04,0.32,0.09,0.64,0.13,0.96c0.22,1.23,0.56,2.37,0.81,3.54c-0.28,0.92-0.27,1.99,0.17,3.19c0.12,0.33,0.24,0.66,0.37,0.99
		c2.05,5.57,10.84,5.08,10.8-1.46C71.01,58.22,69.85,54.96,69.32,51.38z"
          />
        </g>
        <g>
          <path
            d="M34.25,19.89c9.41-1.08,18.19-0.2,26.18,5.41c12.74,8.94,17.97,30.8,9.86,43.91c-1.03,1.66-3.73,3.15-5.63,3.12
			c-1.41-0.03-3.27-2.44-4.03-4.18c-1.25-2.86-1.6-6.09-2.63-9.06c-2.23-6.39-5.92-8.87-12.76-8.92c-0.5,0-1,0.01-1.5,0.02
			c-9.03,0.07-8.89,0.09-10.19,8.86c-0.3,2.03-1.59,4.93-3.12,5.47c-1.6,0.57-4.77-0.6-6-2.07c-7.12-8.53-13.52-16.75-20.22-25.62
			c-1.11-1.38-0.76-3.72,0.39-5.4c6.35-8.31,12.18-16.18,18.94-24.15c1.27-1.49,4.49-2.92,5.9-2.3c1.75,0.77,3.04,3.55,3.66,5.72
			C33.92,13.51,33.88,16.55,34.25,19.89z M25.62,54.75c0.77,0.69,0.89,0.46,1.1-0.34c0.08-1.77,0.15-3.53,0.24-5.3
			c0.29-5.99,1.2-6.97,6.98-7.03c3.52-0.03,7.06,0.03,10.57,0.25c9.38,0.59,17.53,6.78,20.34,15.75c0.37,1.19,0.78,2.36,1.41,4.2
			c0.13,0.38,0.66,0.36,0.78-0.02c0.42-1.4,0.67-2.13,0.78-2.88c2.3-16.44-8.28-34.74-28.72-33.53
			c-11.05,0.65-11.08,0.09-12.48-10.3c-0.01-0.11,0.01-1.5-0.84-0.69c-4.7,5.9-9.48,11.76-14.02,17.8c-1.63,1.7-1.51,2.09-0.27,3.96
			C15.94,42.84,21.01,48.66,25.62,54.75z"
          />
        </g>
      </svg>
      <div className={styles.label}>UNDO</div>
    </button>
  )
}

export default UndoButton

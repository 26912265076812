import React from 'react'

import styles from './RedoButton.module.scss'

interface RedoButtonProps {
  onClick: () => void
}

const RedoButton: React.FC<RedoButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.RedoButton} onClick={onClick}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 78.14 75.91"
      >
        <g>
          <path
            fill="#ffffff"
            d="M8.86,51.27c0.37-2.46,0.65-4.7,1.69-6.7c0.6-3.88,2.16-7.71,5.05-10.12c1.82-2.77,4.62-5.27,7.74-6.02
			c2.29-1.77,5.15-2.81,7.93-2.69c0.26-0.17,0.53-0.34,0.8-0.49c2.04-1.12,3.84-1.49,5.41-1.33c1.83-0.47,3.84-0.26,5.92,0.92
			c0.22-0.18,0.44-0.35,0.67-0.52c0.97-0.7,2.01-0.94,3.02-0.84c-0.02-2.36,0.01-4.71,0.22-7.02c0.65-6.96,10.7-7.18,11,0
			c0.1,2.38,3.94,5.9,5.2,7.85c1.73,2.68,3.24,5.47,4.63,8.35c1.53,3.15-0.05,6.12-2.4,7.52c-0.24,0.39-0.48,0.79-0.71,1.19
			c-2.66,4.54-5.33,9.03-8.52,13.23c-2.98,3.93-10.53,3.26-10.25-2.78c0.06-1.23,0.11-2.46,0.17-3.69
			c-1.47-0.76-2.37-2.01-2.94-3.53c-1.61,0.25-3.24-0.25-4.64-1.24c-0.47,0.09-0.98,0.12-1.51,0.11c-0.06,0.05-0.11,0.11-0.17,0.16
			c-2.14,1.78-4.72,1.9-6.94,0.95c-1.09,1.14-2.47,1.95-3.93,2.34c-1.08,1.72-2.61,3.08-4.46,3.66c-0.25,0.9-0.66,1.79-1.26,2.68
			c-0.35,0.52-0.75,0.95-1.17,1.31c-0.04,0.32-0.09,0.64-0.13,0.96c-0.22,1.23-0.56,2.37-0.81,3.54c0.28,0.92,0.27,1.99-0.17,3.19
			c-0.12,0.33-0.24,0.66-0.37,0.99c-2.05,5.57-10.84,5.08-10.8-1.46C7.17,58.11,8.33,54.85,8.86,51.27z"
          />
        </g>
        <g>
          <path
            d="M43.93,19.79c-9.41-1.08-18.19-0.2-26.18,5.41C5.01,34.14-0.22,56,7.89,69.1c1.03,1.66,3.73,3.15,5.63,3.12
				c1.41-0.03,3.27-2.44,4.03-4.18c1.25-2.86,1.6-6.09,2.63-9.06c2.23-6.39,5.92-8.87,12.76-8.92c0.5,0,1,0.01,1.5,0.02
				c9.03,0.07,8.89,0.09,10.19,8.86c0.3,2.03,1.59,4.93,3.12,5.47c1.6,0.57,4.77-0.6,6-2.07c7.12-8.53,13.52-16.75,20.22-25.62
				c1.11-1.38,0.76-3.72-0.39-5.4C67.22,23.03,61.4,15.16,54.64,7.18c-1.27-1.49-4.49-2.92-5.9-2.3c-1.75,0.77-3.04,3.55-3.66,5.72
				C44.26,13.4,44.3,16.44,43.93,19.79z M52.56,54.64c-0.77,0.69-0.89,0.46-1.1-0.34c-0.08-1.77-0.15-3.53-0.24-5.3
				c-0.29-5.99-1.2-6.97-6.98-7.03c-3.52-0.03-7.06,0.03-10.57,0.25c-9.38,0.59-17.53,6.78-20.34,15.75
				c-0.37,1.19-0.78,2.36-1.41,4.2c-0.13,0.38-0.66,0.36-0.78-0.02c-0.42-1.4-0.67-2.13-0.78-2.88
				c-2.3-16.44,8.28-34.74,28.72-33.53c11.05,0.65,11.08,0.09,12.48-10.3c0.01-0.11-0.01-1.5,0.84-0.69
				c4.7,5.9,9.48,11.76,14.02,17.8c1.63,1.7,1.51,2.09,0.27,3.96C62.24,42.73,57.17,48.55,52.56,54.64z"
          />
        </g>
      </svg>
      <div className={styles.label}>REDO</div>
    </button>
  )
}

export default RedoButton

import React, { useCallback, useState, useLayoutEffect } from 'react'
import styles from './SequencerTitle.module.scss'
import { isMobile, isWebkit } from '../shared/utils'

interface SequencerTitleProps {
  onClick: () => void
}

const SequencerTitle: React.FC<SequencerTitleProps> = ({ onClick }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const handleMouseMove = useCallback(({ clientX, clientY }: MouseEvent) => {
    setMousePosition({
      x: clientX,
      y: clientY,
    })
  }, [])
  const getContainerStyle = useCallback(() => {
    if (isMobile || isWebkit) {
      return {}
    }

    const { x, y } = mousePosition
    const dx = x - window.innerWidth / 2
    const dy = y - window.innerHeight / 2

    return {
      transform: `perspective(600px) rotateY(${dx / 70}deg) rotateX(${
        -dy / 70
      }deg)`,
    }
  }, [mousePosition])
  useLayoutEffect(() => {
    if (isMobile || isWebkit) {
      return
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <svg
      className={styles.SequencerTitle}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 643 206"
      style={{
        ...getContainerStyle(),
      }}
      onClick={onClick}
    >
      <g>
        <path
          fill="#ff7071"
          d="M619,118.79c-2.99,0.02-6.48,0.32-9.28-0.62c-2.11-0.71-3.72-1.52-5.61-2.04c-0.68-0.54-1.45-1.11-2.31-1.63
		c2.87-2.96,4.54-6.68,7.23-9.82c4.95-5.79-1.3-13.81-7.06-11.83c4.53-4.03,9.21-7.9,14.07-11.53c7.28-5.44,1.24-15.64-7.07-12.09
		c-1.78,0.76-3.54,1.55-5.29,2.36c-0.79-0.06-1.59-0.01-2.38,0.16l-0.19,0.04c-1.11,0.25-2.02,0.68-2.74,1.24
		c-0.75,0.28-1.92,0.85-2.41,0.99c-0.44,0.13-0.89,0.24-1.33,0.36c-0.91-0.13-1.82-0.1-2.68,0.16c-1.1,0.33-2.16,0.72-3.17,1.18
		c-2.01,0.41-4.02,0.81-6.02,1.27c-0.92,0.21-1.83,0.44-2.71,0.7c-0.45-0.69-1.02-1.3-1.67-1.8c-0.59-1.16-1.43-1.98-2.39-2.53
		c0.87-1.68,1.75-3.37,2.62-5.05c6.15-11.31,12.93-22.27,21.22-32.14c5.72-6.81-3.32-16.27-9.9-9.9c-3.86,3.73-9.81,5.9-14.53,8.37
		c-5.32,2.78-10.67,5.5-16.05,8.15c-11.05,5.45-22.23,10.62-33.52,15.55c-0.19,0.08-0.35,0.19-0.53,0.29
		c-0.25-0.2-0.5-0.41-0.74-0.6c-0.83-0.96-1.78-1.8-2.91-2.51c0.6-1.68,1.23-3.36,1.9-5.02c3.09-7.62,7.18-14.82,11.17-21.99
		c3.17-5.69-3.6-13.9-9.58-9.58c-7.13,5.15-16.03,10.21-21.97,17.22c-1.74-1.39-3.77-2.32-6.31-2.43c-1.67-0.07-3.07,0.44-4.16,1.29
		c-2.63-3.71-5.43-7.66-9.49-9.61c1.62-4.9-2.49-11.7-8.3-9.3c-0.36,0.15-0.72,0.32-1.08,0.47c-2.93-0.14-5.24,1.21-6.48,3.28
		c-3.1,1.83-6,3.95-8.64,6.42c-3.41,1.73-6.54,3.93-8.74,6.9c-1.94,0.37-3.82,1.05-5.56,2.09c-0.82,0.5-1.61,1.1-2.32,1.79
		c-1.54-5.02-3.24-9.98-5.12-14.9c-1.35-3.54-4.67-5.97-8.61-4.89c-6.88,1.88-9.45,7.27-14.38,11.66c-3.65,3.26-7.24,5.31-9.95,8.71
		c-6.38-6.67-13.48-12.55-21.05-17.91c-2.62-1.85-6.3-0.86-8.48,1.09c-2.83,2.52-5.9,4.12-9.53,5.16c-0.29,0.08-0.56,0.18-0.82,0.28
		c-1.75-2.33-3.55-4.63-5.39-6.9c-2.56-3.16-7.2-2.15-9.9,0c-5.48,4.37-10.17,9.45-14.22,15.1c-4.04-4.33-8.08-8.66-12.12-12.99
		c-2.69-2.88-7.15-2.45-9.9,0c-2.92,2.6-8.1,6-11.42,9.94c-5.76-5.24-11.31-10.69-16.65-16.37c-3-3.19-8.92-2.5-10.99,1.42
		c-3.6,6.81-7.21,13.62-10.81,20.43c-1.69-2.59-3.4-5.17-5.12-7.74c0.03-3.73-3.46-7.44-7.48-7.4c-3.52-0.79-6.91,1.76-8,4.99
		c-0.64,0.37-1.25,0.88-1.79,1.58c-0.17,0.22-0.35,0.43-0.52,0.65c-1.94,1.88-3.81,3.81-5.63,5.81c-2.01,1.51-4.11,2.92-5.84,4.7
		c-0.96-0.31-1.92-0.59-2.88-0.79c-3.67-5.19-7.2-10.49-10.83-15.66c-2.76-3.94-9.6-5.11-12.09,0c-2.18,4.48-4.12,9.06-5.81,13.74
		c-0.36-0.26-0.73-0.5-1.13-0.73c-1.45-0.84-3.06-1.29-4.66-1.35c-2.05-3.1-4.24-6.23-8.18-6.85c-6.25-0.97-9.02,4.2-10.65,9.86
		c-1.36-1.61-2.88-2.97-4.67-3.84c-0.19-0.1-0.4-0.17-0.6-0.25c-0.26-0.79-0.51-1.59-0.73-2.4c-1.76-6.44-12-6.9-13.5,0
		c-1.89,8.69-4.58,17.1-8.19,25.19c-3.95-2.19-7.95-4.27-12.04-6.22c-1.06-0.51-2.16-0.73-3.22-0.74
		c-3.45-2.95-6.25-6.51-8.86-10.35c-4.12-6.07-12.22-3.3-13.04,3.53c-0.54,4.46,0.4,8.7,0.61,13.14c0.17,3.67-0.71,7.19-1.6,10.71
		c-3.33-1.08-6.71-1.99-10.18-2.19c-4.77-2.51-9.43-5.26-14.09-8.01c-7.97-4.72-14.05,6.8-7.07,12.09c2.05,1.55,3.39,3.51,4.64,5.48
		c-6.7-0.12-12.93,1.8-19.36,3.41c-6.53,1.64-13.39,0.68-19.79,2.77c-0.89,0.29-1.66,0.76-2.33,1.32c-1.54-0.3-3.1-0.55-4.68-0.79
		c-3.77-0.56-7.48,0.94-8.61,4.89c-0.95,3.29,1.16,8.06,4.89,8.61c5.45,0.81,10.89,1.63,15.8,4.26c3.25,1.74,6.39,3.72,9.51,5.71
		c-0.86,1.67-0.8,3.43-0.15,4.99c-1.72,1.78-4.22,3.67-5.18,4.67c-2.23,2.31-4.15,4.47-7.08,5.86c-7.45,3.52-2.02,14.46,5.39,12.79
		c2.83-0.63,5.64-0.98,8.46-1.08c0.89,0.47,1.85,0.78,2.8,0.89c-0.14,0.43-0.26,0.86-0.37,1.29c-0.62,1.91-1.24,3.81-1.86,5.72
		c-0.53,1.63-0.27,3.84,0.68,5.46c-0.02,0.07-0.03,0.12-0.05,0.19c-0.5,1.23-1,2.47-1.5,3.7c-1,2.47-2,4.94-3,7.41
		c-2.62,6.47,7.31,11.94,11.7,6.81c1.28-1.5,2.59-2.98,3.92-4.44c0.63,0.52,1.36,0.92,2.19,1.15c1.08,0.3,2.16,0.61,3.24,0.91
		c0.05,0.01,0.09,0.01,0.14,0.03c-1.15,8.18-1.79,16.4-1.79,24.68c0,6.6,7.42,8.53,11.95,4.95l36.33-28.68
		c0.29,0.74,0.64,1.4,1.05,1.97c0.97,1.93,1.93,3.86,2.9,5.79c2.88,5.75,10.99,3.63,12.79-1.67c0.61-1.8,1.3-3.55,2.07-5.27
		c0.79,0.26,1.62,0.38,2.46,0.31c0.01,0.01,0.01,0.01,0.02,0.02c4.25,7.43,9.53,14.22,15.71,20.18c3.24,3.13,8.67,2.52,10.99-1.42
		c8.26-14.01,20.67-24.72,31.48-36.7c0.07,0.06,0.15,0.13,0.22,0.19c0.32,1,0.92,1.98,1.88,2.89c3.62,3.46,7.05,7.1,10.21,10.97
		c-0.42,4.37,3.87,7.45,8.08,6.91c3.86,0.62,7.9-1.63,7.94-6.62c0-0.02,0.01-0.05,0.01-0.08c1.54-1.97,3.1-3.91,4.68-5.84
		c2.36,6.26,4.99,12.42,7.96,18.42c1.88,3.82,4,8.31,8.71,8.98c5.59,0.79,8.08-3.25,9.94-7.75c0.6-1.46,1.24-2.88,1.9-4.3
		c0.28-0.19,0.56-0.4,0.82-0.64c4.97-4.53,8.09-11.57,11.4-17.72c3.93,4.81,7.6,9.79,11.05,14.96c3.21,4.81,11.24,4.45,12.79-1.67
		c0.87-3.44,1.86-6.85,3-10.21c1.43,0.55,2.87,1.01,4.33,1.37c0.4,0.26,0.81,0.5,1.2,0.77c1.91,1.31,3.75,2.73,5.6,4.12
		c3.47,2.61,6.94,5.22,10.34,7.93c0.38,0.3,0.75,0.62,1.13,0.93c0.12,0.19,0.24,0.39,0.38,0.58c3.26,4.45,6.78,8.54,10.78,12.25
		c0.2,1.05,0.5,2.11,0.92,3.2c1.39,3.54,4.89,5.17,8.02,4.83c5.14,3.42,11.25-2.01,10.38-7.91c-0.15-1.04-0.45-2.03-0.85-2.98
		c0.87-0.81,1.49-1.95,1.7-3.43c0.09-0.62,0.16-1.24,0.24-1.86l0.01,0c0-0.05,0.01-0.09,0.01-0.14c0.63-4.74,1.14-9.48,1.53-14.24
		c7.32,4.53,12.91,11.55,19.88,16.62c3.38,2.45,9.66,0.07,10.28-4.18c0.19-1.29,0.4-2.58,0.63-3.86c0.14-0.21,0.27-0.43,0.38-0.67
		c1.89-4.09,3.63-8.24,5.26-12.43c6.39,2.15,12.13,5.61,17.82,9.19c5.03,3.16,10.14,6.13,12.84,11.64
		c3.37,6.86,12.41,2.67,13.04-3.53c0.35-3.41,0.91-6.79,1.62-10.14c2.14-4.59,3.99-9.3,5.53-14.11
		c13.06,6.91,25.83,14.31,38.31,22.22c4.24,2.69,11-0.75,10.53-6.04c-0.35-3.98-0.36-7.93-0.15-11.87c0.08-0.84,0.17-1.68,0.25-2.51
		c6.85,2.7,13.42,5.94,19.8,9.68c5.69,3.33,11.25-2.14,10.28-7.91c-0.03-0.21-0.06-0.42-0.09-0.63c1.56,0.69,3.17,1.09,4.9,1.3
		c1.56,1.02,3.15,2.33,4.04,2.87c2.88,1.77,5.75,3.54,8.63,5.31c5.69,3.5,11.37,7,17.06,10.49c10.56,6.5,22.62,12.31,30.93,21.72
		c4.6,5.2,13.87-0.28,11.7-6.81c-1.59-4.79-2.11-9.82-2.17-14.89c0.62-0.99,1-2.23,1.01-3.71c0.03-4.87,0.22-9.73,0.55-14.58
		c0.44-4.11,1.12-8.2,2.08-12.29c0.27-1.17,0.26-2.25,0.05-3.22c0.13-0.12,0.25-0.25,0.38-0.37c3.33-0.9,6.71-1.55,10.18-1.78
		c4.62-0.31,8.03-0.07,11.75-3.21C628.71,126.73,624.74,118.74,619,118.79z"
        />
      </g>
      <g>
        <g>
          <path
            d="M608.21,138.11c-0.22,3.37-0.27,6.57-0.67,9.71c-0.6,4.75-1.31,9.51-2.22,14.21c-2.01,10.32-1.89,20.67-0.75,31.06
			c0.1,0.95,0.31,1.91,0.28,2.86c-0.1,2.78-2.72,4.64-5.39,3.84c-0.91-0.27-1.73-0.82-2.6-1.2c-17.33-7.7-33.34-17.63-48.69-28.68
			c-6.25-4.5-12.99-8.18-19.78-11.75c-0.39-0.21-0.89-0.22-1.59-0.38c-0.79,1.53-0.53,3.1-0.43,4.63c0.06,0.95,0.37,1.88,0.47,2.84
			c0.33,3.27-2.34,5.53-5.49,4.62c-0.91-0.26-1.8-0.69-2.63-1.16c-4.87-2.75-9.73-5.53-14.58-8.33c-2.07-1.19-4.09-2.46-6.42-3.86
			c-0.43,0.84-0.93,1.45-1.06,2.13c-0.8,4.11-1.08,8.23-0.15,12.38c0.21,0.93,0.35,1.9,0.34,2.84c-0.02,2.29-1.73,3.98-4.04,3.7
			c-1.55-0.19-3.12-0.72-4.53-1.42c-6.45-3.2-12.86-6.47-19.24-9.79c-7.51-3.91-14.97-7.89-22.7-11.97
			c-0.49,0.71-1.09,1.26-1.31,1.93c-1.45,4.4-2.92,8.79-4.16,13.25c-0.9,3.26-0.96,6.62-0.59,10.01c0.15,1.41,0.13,2.88-0.12,4.27
			c-0.42,2.37-2.13,3.64-4.48,3.29c-1.39-0.21-2.8-0.73-4.03-1.42c-2.37-1.32-4.72-2.73-6.89-4.34
			c-6.67-4.96-13.26-10.03-19.87-15.08c-2.4-1.84-4.7-3.82-7.62-5.44c-0.63,0.82-1.33,1.45-1.7,2.23
			c-2.43,5.22-4.75,10.49-5.72,16.24c-0.11,0.63-0.29,1.25-0.53,1.84c-0.74,1.77-2.74,2.99-4.63,2.67
			c-1.09-0.18-2.21-0.53-3.16-1.08c-6.63-3.84-13.64-7.1-19.42-12.28c-0.22-0.2-0.56-0.27-0.86-0.41c-1.06,0.13-1.04,1.02-1.18,1.76
			c-1.14,6.15-1.91,12.33-1.7,18.6c0.05,1.44,0.1,2.89-0.02,4.32c-0.22,2.51-1.92,4.21-4.42,4.08c-1.71-0.09-3.48-0.56-5.07-1.22
			c-7.64-3.16-14.33-7.71-19.96-13.81c-5.64-6.1-11.04-12.46-17.5-17.75c-2.45-2-4.79-4.16-7.8-5.85c-0.58,0.76-1.13,1.32-1.48,1.98
			c-1.97,3.7-3.29,7.62-4.09,11.73c-0.27,1.4-0.61,2.84-1.22,4.11c-1.16,2.42-4.02,3.1-6.05,1.38c-1.44-1.23-2.69-2.73-3.82-4.27
			c-3.02-4.13-5.9-8.36-8.89-12.51c-1.01-1.4-1.87-2.99-3.79-3.87c-0.47,0.6-1,1.16-1.39,1.8c-3.69,6.18-7.42,12.34-10.98,18.59
			c-1.74,3.04-3.14,6.27-4.69,9.42c-0.14,0.29-0.25,0.59-0.36,0.89c-0.67,1.8-1.91,2.99-3.84,3.2c-2,0.21-3.34-0.95-4.25-2.57
			c-4.46-7.95-10.37-15.01-14.07-23.41c-0.63-1.43-1.5-2.74-2.4-4.37c-2.56,2.11-3.92,4.63-5.31,7.12
			c-0.62,1.11-1.07,2.33-1.74,3.42c-1.76,2.88-4.25,3.4-6.82,1.26c-1.96-1.63-3.79-3.44-5.54-5.31c-2.4-2.57-4.71-5.23-6.99-7.9
			c-2.48-2.91-5.06-5.71-8.1-8.51c-1.1,1.01-2.13,1.74-2.89,2.69c-4.29,5.37-8.52,10.79-12.76,16.21
			c-3.25,4.16-6.49,8.32-9.68,12.52c-1.74,2.29-3.35,4.68-5.05,7c-2.28,3.1-4.7,3.51-7.8,1.29c-0.9-0.64-1.68-1.47-2.44-2.28
			c-4.4-4.68-8.26-9.75-11.26-15.47c-1.18-2.24-2.29-4.55-4.1-6.75c-0.68,0.63-1.34,1.07-1.78,1.67c-2.06,2.85-4.02,5.78-6.1,8.62
			c-0.84,1.15-1.79,2.28-2.88,3.17c-2.17,1.78-5.24,1.22-6.53-1.28c-0.95-1.83-1.49-3.87-2.21-5.81c-0.65-1.76-0.84-3.71-2.01-5.31
			c-7.71,4.41-14.47,9.69-20.73,15.64c-6.17,5.86-12.8,11.11-20.4,15.04c-1.27,0.66-2.58,1.34-3.95,1.67
			c-3.24,0.77-5.29-0.45-6.24-3.61c-0.32-1.06-0.48-2.2-0.47-3.3c0.01-7.35-0.46-14.72,0.6-22.05c0.29-2.04,0.39-4.11,0.27-6.48
			c-0.87,0.22-1.53,0.23-2,0.53c-4.72,3.01-9.42,6.04-14.09,9.11c-1.81,1.19-3.65,2.22-5.73,0.85c-2.01-1.33-2.16-3.34-1.69-5.55
			c2.15-10.16,4.08-20.38,7.84-30.13c0.39-1.01,0.61-2.08,0.97-3.34c-0.88-0.16-1.47-0.37-2.06-0.35
			c-5.27,0.11-10.56-0.23-15.79,0.83c-1.54,0.31-3.19,0.28-4.78,0.16c-2.75-0.22-4.39-2.32-3.7-4.99c0.38-1.48,1.25-2.96,2.27-4.12
			c2.72-3.14,5.62-6.12,8.48-9.15c2.96-3.14,5.97-6.25,8.92-9.39c0.75-0.8,1.39-1.71,2.27-2.8c-1.05-0.74-1.85-1.49-2.8-1.94
			c-4.05-1.9-8.15-3.7-12.24-5.55c-4.81-2.17-9.6-4.37-14.42-6.52c-3.5-1.56-7.02-3.07-10.54-4.59c-2.31-1-3.08-2.24-2.81-4.4
			c0.15-1.21,0.69-2.2,1.8-2.64c1.47-0.6,2.99-1.21,4.54-1.44c5.53-0.84,11.08-1.5,16.62-2.28c4.75-0.67,9.51-1.26,14.22-2.17
			c8.32-1.61,16.62-3.34,25.1-3.93c0.92-0.06,1.83-0.38,3.06-0.64c-0.43-0.95-0.66-1.68-1.07-2.3c-2.62-4.02-5.3-8-7.9-12.03
			c-0.86-1.34-1.71-2.72-2.31-4.19c-0.7-1.74-0.52-3.49,0.93-4.86c1.3-1.22,2.84-1.35,4.47-0.74c0.6,0.22,1.23,0.39,1.78,0.7
			c7.01,3.94,14.89,5.78,22.18,9.04c3.5,1.57,7.1,2.9,10.67,4.3c0.7,0.28,1.47,0.38,2.61,0.66c0.1-1.57,0.26-2.79,0.24-4
			c-0.18-8.16-0.41-16.31-0.61-24.47c-0.03-1.28-0.08-2.57,0.09-3.83c0.49-3.6,4.07-5.27,6.66-2.86c6.7,6.24,14.73,10.73,21.6,16.74
			c1.8,1.57,3.79,2.95,5.74,4.33c0.74,0.52,1.64,0.82,2.75,1.37c0.4-0.97,0.76-1.64,0.95-2.36c1.81-6.99,4.37-13.7,7.5-20.2
			c1.04-2.16,1.89-4.41,2.84-6.61c0.38-0.88,0.68-1.81,1.2-2.6c1.54-2.32,4.2-2.63,6.15-0.71c0.78,0.77,1.34,1.77,1.98,2.69
			c2.11,3.01,4.2,6.03,6.33,9.03c0.73,1.02,1.56,1.97,2.12,2.68c1.8-0.33,1.97-1.34,2.26-2.19c0.66-1.97,1.21-3.98,1.89-5.94
			c0.42-1.2,0.88-2.42,1.52-3.51c1.92-3.24,5.22-3.84,8.1-1.4c1.2,1.02,2.06,2.42,3.11,3.62c1.58,1.8,3.12,3.64,4.79,5.35
			c0.98,1.01,2.18,1.81,3.53,2.91c0.73-1.12,1.39-1.98,1.88-2.92c2.14-4.11,4.17-8.28,6.39-12.35c0.75-1.37,1.77-2.69,2.96-3.69
			c2.15-1.82,4.59-1.46,6.21,0.83c1.84,2.61,3.45,5.39,5.27,8.01c2.1,3.02,4.26,5.99,6.51,8.9c0.95,1.23,2.17,2.24,3.16,3.24
			c0.63-0.23,1.02-0.25,1.21-0.45c4.79-5.15,9.56-10.32,14.33-15.49c0.11-0.12,0.21-0.24,0.33-0.35c3.73-3.72,6.8-3.66,10.02,0.49
			c2.44,3.15,4.59,6.54,6.87,9.82c0.54,0.77,1.13,1.51,1.93,2.59c0.72-0.89,1.4-1.5,1.78-2.25c3.22-6.27,6.75-12.35,11.14-17.89
			c0.79-1,1.58-2.03,2.53-2.87c2.42-2.14,5.23-2.05,7.05,0.62c4.37,6.4,10.43,11.25,15.3,17.17c0.29,0.35,0.77,0.53,1.38,0.95
			c0.82-0.68,1.71-1.29,2.45-2.05c2.91-2.99,5.98-5.79,9.5-8.05c1.34-0.86,2.73-1.66,4.18-2.33c3.04-1.4,4.94-0.95,6.41,1.89
			c2.55,4.92,6.68,8.51,10.19,13.02c0.85-0.64,1.64-1.1,2.25-1.73c3.65-3.81,7.24-7.68,10.91-11.47c1.33-1.37,2.73-2.73,4.28-3.83
			c2.91-2.07,5.27-1.72,7.53,1.04c1.42,1.73,2.66,3.6,4.01,5.39c1.23,1.64,2.27,3.45,4.23,4.88c0.77-0.58,1.52-1.11,2.23-1.68
			c2.37-1.9,4.72-3.82,7.09-5.72c0.62-0.5,1.25-1.01,1.94-1.4c2.85-1.61,4.72-1.21,7.15,1.42c4.01,4.35,8.02,8.7,12.06,13.03
			c0.98,1.05,2.02,2.05,3.15,2.94c0.99,0.78,2.13,1.38,3.46,2.23c2.31-2.02,4.57-4.02,6.85-5.99c3.76-3.23,7.43-6.58,11.35-9.6
			c2.64-2.04,5.54-3.81,8.51-5.34c3.61-1.85,6.05-0.59,7.29,3.3c1.46,4.57,2.92,9.14,4.39,13.7c0.24,0.73,0.57,1.43,0.79,1.98
			c1.4,0.51,1.99-0.35,2.71-0.94c5.23-4.21,10.35-8.56,15.75-12.55c3.2-2.36,6.76-4.27,10.27-6.16c4.98-2.69,7.11-2.28,10.78,2.18
			c2.12,2.58,3.97,5.41,5.8,8.22c2.28,3.49,4.58,6.94,7.81,9.66c2.88,2.42,3.19,2.64,6.31,0.79c4.54-2.68,8.88-5.7,13.37-8.45
			c4.09-2.5,8.2-4.99,12.44-7.22c2.38-1.25,5.02-2.05,7.61-2.87c1.01-0.32,2.23-0.32,3.28-0.11c2.28,0.44,3.6,2.35,3.13,4.6
			c-0.35,1.71-1.02,3.36-1.64,5c-2.84,7.64-5.73,15.27-8.57,22.91c-0.43,1.16-0.7,2.37-1.02,3.46c1.21,1,2.05,0.19,2.81-0.18
			c7.34-3.56,14.68-7.11,21.95-10.8c8.71-4.42,17.47-8.74,26.73-11.84c7.27-2.43,14.67-4.5,22.05-6.6c1.5-0.43,3.18-0.52,4.73-0.38
			c2.44,0.23,3.7,2.79,2.64,5.03c-0.34,0.72-0.82,1.37-1.23,2.05c-2.38,3.97-4.79,7.93-7.14,11.92
			c-4.05,6.89-8.12,13.78-12.08,20.72c-1.9,3.33-3.58,6.79-5.33,10.2c-0.27,0.54-0.4,1.15-0.7,2.05c1.05,0,1.82,0.11,2.54-0.02
			c11.66-2.14,23.05-5.34,34.38-8.75c1.82-0.55,3.72-0.93,5.61-1.18c3.09-0.42,5.8,3.46,3.81,6.26c-1.57,2.21-3.19,4.41-4.98,6.44
			c-4.13,4.68-8.39,9.24-12.56,13.87c-0.82,0.91-1.52,1.92-2.64,3.36c1.86,0,3.09,0.13,4.28-0.03c2.22-0.3,4.4-0.81,6.61-1.15
			c1.09-0.17,2.22-0.25,3.32-0.2c1.18,0.06,2.17,0.63,2.59,1.82c0.45,1.28,0.36,2.55-0.6,3.58c-0.54,0.58-1.23,1.01-1.84,1.53
			c-4.61,3.95-9.24,7.9-13.82,11.88c-0.81,0.71-1.45,1.61-2.27,2.54c1.36,1.75,3.2,2.4,4.89,3.19c5.21,2.46,10.33,5.09,15.11,8.33
			c0.79,0.54,1.64,1.03,2.31,1.7c1.99,1.98,1.83,4.96-0.39,6.73c-0.74,0.59-1.61,1.05-2.5,1.4c-3.71,1.47-7.39,3.09-11.2,4.25
			C615.31,136.95,611.96,137.36,608.21,138.11z M56.99,162.82c0.74-0.37,1.6-0.71,2.37-1.19c2.57-1.61,5.05-3.39,7.7-4.87
			c1.5-0.84,3.22-1.48,4.92-1.76c2.55-0.42,4.72,1.18,5.39,3.71c0.28,1.07,0.45,2.21,0.4,3.31c-0.14,3.35-0.17,6.74-0.67,10.05
			c-0.81,5.42-0.52,10.85-0.55,16.29c0,0.7,0.38,1.41,0.64,2.27c2.6-0.9,4.67-2.15,6.5-3.65c4.69-3.85,9.36-7.75,13.83-11.86
			c3.55-3.26,7.35-6.18,11.34-8.83c2.67-1.78,5.25-3.61,7.55-5.88c1.22-1.2,2.78-2.16,4.34-2.92c1.96-0.95,3.71-0.43,5.2,1.17
			c1.13,1.2,1.75,2.65,2.01,4.27c0.23,1.42,0.46,2.84,0.8,4.24c0.17,0.7,0.17,1.65,1.56,1.69c1.19-1.71,2.47-3.53,3.74-5.35
			c0.92-1.31,1.7-2.75,2.8-3.88c2.77-2.83,6.06-3.6,9.05-0.04c1.23,1.46,2.37,3.02,3.39,4.64c1.19,1.89,2.25,3.87,3.3,5.85
			c2.18,4.12,4.84,7.91,7.83,11.47c0.47,0.56,1.15,0.96,1.87,1.54c0.74-0.73,1.36-1.22,1.85-1.82c2.5-3.12,4.99-6.25,7.42-9.42
			c5.26-6.85,10.43-13.77,15.76-20.56c1.77-2.25,3.78-4.35,5.89-6.3c3.94-3.63,6.23-3.43,9.65,0.52c4.18,4.84,8.35,9.68,12.56,14.5
			c1.34,1.53,2.57,3.24,4.88,4.06c1.01-1.33,1.94-2.6,2.92-3.82c2.01-2.48,3.87-5.13,6.13-7.36c3.04-3,6.63-2.23,8.66,1.57
			c0.52,0.98,0.84,2.07,1.27,3.11c2.68,6.54,6.09,12.69,9.98,18.56c0.73,1.09,1.27,2.64,3.21,2.66c1.31-2.19,2.64-4.35,3.91-6.56
			c3.83-6.65,8.28-12.93,11.51-19.93c0.46-1,1.15-1.94,1.88-2.77c1.61-1.83,3.44-2.18,5.59-0.94c1.23,0.7,2.32,1.68,3.37,2.65
			c3.56,3.29,6.14,7.35,8.91,11.27c0.99,1.39,1.78,2.98,3.62,4.13c0.59-1.37,1.02-2.53,1.58-3.62c1.47-2.83,2.79-5.77,4.57-8.4
			c3.12-4.62,5.96-4.93,10.43-1.56c8.97,6.76,17.27,14.22,24.53,22.84c4.04,4.8,8.79,8.85,14.36,11.83c0.94,0.5,1.92,1.27,3.3,0.37
			c0.09-0.96,0.27-2.05,0.26-3.15c-0.03-4.5,0.7-8.92,1.46-13.33c0.6-3.46,1.13-6.94,1.75-10.4c0.2-1.09,0.5-2.18,0.88-3.22
			c0.72-1.96,2.09-3.21,4.23-3.39c1.97-0.16,3.37,0.85,4.37,2.43c0.77,1.22,1.44,2.49,2.08,3.78c1.4,2.8,3.44,4.99,6.09,6.62
			c2.99,1.85,5.98,3.71,9.01,5.5c0.62,0.37,1.43,0.41,2.33,0.65c0.95-2.48,1.7-4.75,2.67-6.92c1.77-3.93,3.53-7.89,5.56-11.68
			c1.55-2.89,4.82-3.84,7.85-2.62c1.03,0.41,2.02,0.98,2.91,1.63c3.74,2.74,7.44,5.54,11.14,8.33c5.49,4.13,10.97,8.29,16.49,12.38
			c0.68,0.51,1.62,0.67,2.39,0.97c0.25-0.44,0.37-0.57,0.39-0.72c0.12-0.79,0.24-1.58,0.32-2.37c0.81-8.23,3.59-15.88,6.97-23.34
			c1.86-4.1,5.28-6.41,10.42-2.78c3.9,2.75,8.21,4.92,12.41,7.23c4.48,2.47,9.04,4.81,13.57,7.18c3.12,1.63,6.25,3.23,9.4,4.8
			c0.67,0.33,1.45,0.44,2.43,0.72c0.16-1.24,0.23-2.17,0.39-3.08c0.8-4.55,1.28-9.2,2.53-13.63c1.26-4.5,5.11-5.65,8.53-2.61
			c4.6,4.1,10.11,6.72,15.17,10.06c0.84,0.56,1.96,0.7,3.29,1.15c0.4-1.93,0.69-3.48,1.06-5c0.34-1.39,0.66-2.81,1.22-4.12
			c1.43-3.38,5.78-5.64,9.16-1.3c1.84,2.36,4.28,3.74,6.79,5.18c5.54,3.18,11.06,6.43,16.42,9.91c6.31,4.08,12.41,8.48,18.67,12.65
			c6.93,4.62,14.24,8.58,21.81,12.07c0.93,0.43,1.88,1.38,3.03,0.47c0.09-0.33,0.28-0.64,0.25-0.93c-0.91-9.97,0.09-19.8,2.12-29.57
			c1.34-6.44,2.03-12.99,1.92-19.55c-0.09-5.73,2.62-8.83,8.22-8.7c3.67,0.09,7.35-0.56,10.75-2.13c0.64-0.29,1.13-0.9,2-1.62
			c-0.7-0.5-1.06-0.84-1.48-1.05c-5.72-2.84-11.39-5.81-17.22-8.42c-1.95-0.87-2.62-2.17-2.68-4.02c-0.06-1.71,0.6-3.12,1.78-4.3
			c2.03-2.04,4.11-4.03,6.12-6.09c0.48-0.49,1.39-0.85,0.92-1.99c-1.39,0.27-2.77,0.63-4.18,0.79c-1.98,0.23-3.84-0.04-5.1-1.88
			c-1.33-1.95-1.22-3.9,0-5.84c0.34-0.54,0.71-1.07,1.12-1.56c1.02-1.23,2.05-2.45,3.11-3.65c3.93-4.42,7.88-8.83,11.81-13.26
			c0.41-0.46,0.87-0.98,0.13-1.68c-0.39,0.03-0.88-0.03-1.31,0.1c-9.95,3.04-20.14,5.01-30.36,6.85c-1.56,0.28-3.2,0.49-4.75,0.32
			c-3.11-0.35-4.98-3.37-4.07-6.35c0.32-1.06,0.89-2.05,1.42-3.03c1.14-2.11,2.35-4.18,3.5-6.28c3.15-5.75,6.25-11.52,9.45-17.24
			c2.81-5.02,5.75-9.98,8.6-14.98c0.44-0.77,1.2-1.61,0.15-2.5c-9.79,1.94-21.44,6.73-30.08,11.03
			c-12.6,6.27-25.21,12.49-37.83,18.73c-0.57,0.28-1.16,0.54-1.77,0.72c-4.57,1.42-8.04-1.65-7.15-6.42
			c0.29-1.56,0.85-3.08,1.4-4.58c2.83-7.82,5.7-15.62,8.53-23.44c0.23-0.65,0.93-1.39-0.18-2.2c-1.05,0.54-2.22,1.04-3.28,1.71
			c-4.87,3.07-9.69,6.22-14.58,9.26c-2.44,1.52-4.87,3.11-7.49,4.27c-4.57,2.03-8.96,1.77-12.99-1.64
			c-3.95-3.34-7.22-7.23-9.96-11.59c-1.19-1.89-2.37-3.81-3.68-5.61c-1.76-2.41-2.66-2.72-5.23-1.16
			c-4.23,2.57-8.44,5.21-12.39,8.19c-4.2,3.18-8.08,6.78-12.1,10.2c-0.97,0.83-1.89,1.75-2.96,2.44c-3.11,2.01-6.31,1.3-8.26-1.85
			c-0.67-1.07-1.12-2.3-1.53-3.5c-0.93-2.72-1.76-5.48-2.68-8.21c-0.46-1.36-0.96-2.71-1.57-4.01c-0.46-0.98-1.51-1.31-2.43-0.82
			c-0.84,0.45-1.67,0.95-2.41,1.55c-2.85,2.32-5.7,4.65-8.49,7.04c-3.28,2.81-6.45,5.74-9.75,8.53c-2.79,2.35-5.77,2.44-8.84,0.45
			c-3.37-2.18-6.55-4.53-9.18-7.63c-2.48-2.92-5.17-5.67-7.84-8.43c-0.74-0.76-1.73-1.29-2.77-2.05c-2.08,1.54-3.98,2.96-5.91,4.36
			c-1.42,1.03-2.8,2.15-4.31,3.02c-3.43,1.98-5.92,1.72-8.61-1.13c-1.96-2.08-3.57-4.5-5.39-6.72c-0.56-0.68-1.3-1.21-2.07-1.91
			c-1.04,0.94-1.92,1.63-2.68,2.43c-3.62,3.84-7.2,7.71-10.81,11.56c-0.98,1.05-1.92,2.17-3.05,3.04c-2.86,2.19-5.14,1.95-7.64-0.61
			c-0.33-0.34-0.63-0.73-0.93-1.1c-2.93-3.59-5.84-7.2-8.79-10.77c-0.58-0.7-1.31-1.27-2.03-1.96c-4.04,2.26-7.15,5.2-10.05,8.4
			c-0.97,1.06-1.82,2.25-2.88,3.21c-2.64,2.38-5.78,2.52-8.28-0.47c-4.4-5.27-9.29-10.14-14-15.15c-0.63-0.66-1.43-1.16-2.32-1.87
			c-0.81,0.89-1.53,1.5-2.04,2.26c-3.38,5.05-6.48,10.27-9.03,15.81c-0.79,1.72-1.7,3.47-2.92,4.9c-1.88,2.2-4.66,2.39-6.85,0.51
			c-1.43-1.23-2.65-2.77-3.75-4.33c-2.03-2.87-3.87-5.88-5.88-8.76c-0.5-0.73-1.34-1.22-2.14-1.92c-0.96,0.72-1.8,1.19-2.43,1.85
			c-3.74,3.94-7.43,7.94-11.15,11.89c-1.09,1.15-2.15,2.38-3.42,3.31c-2.03,1.49-4.23,1.35-6.24-0.13c-1.02-0.75-1.91-1.7-2.8-2.61
			c-3.79-3.91-7.24-8.11-10.31-12.61c-0.76-1.12-1.38-2.52-3.34-2.82c-1.53,3.03-2.96,6.04-4.56,8.97
			c-0.99,1.81-2.12,3.58-3.43,5.17c-0.65,0.8-1.78,1.52-2.78,1.66c-2.38,0.33-4.8,0.33-6.79-1.48c-2.6-2.36-5.23-4.69-7.86-7.02
			c-0.55-0.48-1.14-0.91-2.03-1.61c-0.56,1.59-0.97,2.77-1.39,3.94c-0.55,1.5-0.99,3.05-1.69,4.48c-2.69,5.5-6.53,6.4-11.04,2.37
			c-2.37-2.11-4.32-4.7-6.46-7.07c-0.83-0.92-1.65-1.84-2.54-2.83c-0.56,0.52-1.02,0.77-1.2,1.15c-1.23,2.6-2.52,5.18-3.57,7.86
			c-1.7,4.32-3.19,8.71-3.94,13.32c-1.22,7.46-5.66,9.19-12.02,5.16c-0.14-0.09-0.28-0.15-0.41-0.24c-3.27-2.29-6.6-4.51-9.8-6.89
			c-3.85-2.86-7.58-5.88-11.41-8.77c-0.84-0.63-1.63-1.62-3.03-1.19c-0.1,0.42-0.32,0.87-0.31,1.31c0.08,3.2,0.2,6.39,0.32,9.59
			c0.16,4.47,0.57,8.96,0.43,13.42c-0.17,5.27-3.61,7.75-8.79,6.57c-2.48-0.57-4.91-1.44-7.28-2.39c-5.05-2.01-10.03-4.2-15.07-6.23
			c-1.73-0.7-3.44-1.66-5.82-1.53c1.27,3.07,3.33,5.27,5.02,7.71c0.82,1.18,1.79,2.28,2.42,3.55c1.35,2.73,0.46,5.57-2.32,6.85
			c-1.55,0.71-3.35,0.95-5.07,1.17c-6.64,0.88-13.33,1.44-19.94,2.54c-9.13,1.53-18.14,3.79-27.42,4.42
			c-0.36,0.02-0.7,0.46-1.25,0.85c1.31,1.29,2.85,1.74,4.29,2.38c6.57,2.92,13.14,5.87,19.72,8.76c3.07,1.34,5.21,3.6,6.93,6.39
			c1.24,2.01,1.22,3.97-0.07,5.84c-1.08,1.57-2.31,3.07-3.6,4.48c-3.13,3.42-6.35,6.76-9.5,10.16c-0.82,0.89-1.52,1.88-2.64,3.29
			c1.34,0.13,2.09,0.27,2.82,0.25c4-0.08,7.99-0.22,11.99-0.3c1.77-0.03,3.56,0,4.96,1.32c1.93,1.81,2.61,4,1.72,6.54
			c-0.89,2.57-1.95,5.08-2.77,7.67c-1.58,5.03-3.09,10.08-4.52,15.16C56.2,160.12,55.63,161.44,56.99,162.82z"
          />
          <path
            d="M216.4,118.15c-1.17,0.43-2.45,1.17-3.79,1.32c-2.05,0.23-4.15-0.01-6.23,0.06c-2.84,0.08-5.72-0.63-8.43,0.33
			c-0.85,1.49-0.97,2.81,0.12,4.35c1.79-0.59,3.57-1.24,5.38-1.75c1.84-0.53,3.72-0.95,5.59-1.37c1.66-0.37,3.18,0.13,4.58,0.97
			c1.36,0.81,1.96,2.12,1.81,3.66c-0.15,1.49-0.78,2.78-2.33,3.35c-0.45,0.17-0.91,0.31-1.38,0.41c-4.41,0.9-8.57,2.53-12.66,4.37
			c-0.72,0.32-1.51,0.53-2.29,0.7c-4.05,0.85-7.45-1.26-8.19-5.32c-0.37-2.03-0.3-4.14-0.35-6.21c-0.07-2.55,0.08-5.09-1.65-7.31
			c-1.09-1.41-0.39-3.06,0.16-4.78c-4.79,1.61-9.65-0.38-14.52,1.15c0,0.97-0.12,1.89,0.02,2.77c0.81,5.05,1.7,10.08,2.54,15.12
			c0.18,1.1,0.37,2.21,0.37,3.32c-0.01,1.51-0.68,2.78-1.88,3.72c-2.13,1.67-4.85,1.19-6.14-1.17c-0.45-0.81-0.65-1.79-0.82-2.72
			c-1.02-5.82-2-11.66-3.01-17.48c-0.11-0.61-0.37-1.19-0.62-1.96c-0.98-0.15-1.89-0.28-2.81-0.42c-2.05-0.32-4.14-0.48-6.12-1.04
			c-1.16-0.33-2.31-1.13-3.15-2.02c-1.3-1.38-1.57-3.17-0.68-4.91c0.85-1.66,2.41-2.04,4.11-1.74c4.98,0.88,9.86,0.2,14.76-0.69
			c2.04-0.37,4.12-0.52,6.2-0.66c1.59-0.11,3.2-0.1,4.8-0.04c1.27,0.04,2.56,0.14,3.81,0.39c1.03,0.2,2.02,0.65,3.52,1.16
			c-0.48-1.15-0.82-1.83-1.06-2.54c-0.89-2.64,0.08-4.82,2.65-5.7c1.04-0.36,2.19-0.56,3.29-0.54c4.31,0.07,8.63,0.2,12.94,0.45
			c1.25,0.07,2.53,0.45,3.69,0.95c2.22,0.97,3.25,3.02,2.71,5.01c-0.61,2.25-2.13,3.41-4.62,3.34c-1.43-0.04-2.85-0.35-4.29-0.45
			c-1.89-0.13-3.8-0.57-6.02,0.23c-0.02,0.98-0.18,2.05-0.05,3.08c0.26,2.01,0.78,2.58,2.7,2.69c3.35,0.19,6.71,0.24,10.07,0.32
			c4.35,0.1,4.35,0.09,7.9,2.61c0.98-0.4,0.76-1.29,0.78-2.04c0.06-2.24,0.09-4.48,0.08-6.72c-0.01-2.02,0.8-3.73,1.93-5.32
			c1.09-1.54,2.65-2.46,4.49-2.34c5.01,0.32,9.84,1.38,13.84,4.69c1.08,0.9,2.04,2.06,2.76,3.27c3.04,5.12,1.65,11.09-3.4,14.25
			c-1.88,1.18-4,1.98-6.06,2.85c-1.17,0.49-2.42,0.77-3.88,1.22c-0.2,1.04-0.53,2.09-0.58,3.15c-0.09,2.4,0.05,4.8-0.09,7.19
			c-0.07,1.25-0.3,2.58-0.84,3.69c-1.45,3-5.14,3.14-6.98,0.36c-0.92-1.39-1.12-2.97-1.13-4.59c-0.03-3.68-0.05-7.36-0.11-11.03
			C217.78,120.48,217.86,119.15,216.4,118.15z M227.52,106.54c-0.88,2.23-1.09,4.26,0.12,6.35c1.84,0.11,3.21-0.61,4.5-1.52
			c1.16-0.82,1.23-2.3,0.14-3.24C231.08,107.09,229.75,106.29,227.52,106.54z"
          />
          <path
            d="M482.52,114.52c-0.05,3.02-0.07,6.03-0.15,9.04c-0.04,1.27-0.02,2.59-0.36,3.8c-0.82,2.9-3.77,4.28-6.47,2.9
			c-1.36-0.7-2.67-1.82-3.56-3.06c-1.95-2.72-3.66-5.63-5.39-8.5c-1.13-1.88-2.1-3.87-3.51-5.51c-1.03,0.43-0.76,1.16-0.76,1.73
			c-0.03,3.36,0,6.72-0.03,10.08c-0.01,1.12-0.02,2.25-0.21,3.34c-0.29,1.68-1.15,2.98-2.92,3.44c-1.63,0.43-3.27-0.34-4.36-2.21
			c-0.55-0.95-0.92-2.03-1.22-3.09c-0.21-0.76-0.23-1.59-0.22-2.38c0.17-8.15,0.35-16.31,0.57-24.46c0.03-1.11,0.17-2.25,0.51-3.3
			c1.12-3.41,4.58-4.32,7.19-1.87c1.04,0.97,1.98,2.11,2.74,3.31c2.22,3.51,4.32,7.1,6.48,10.66c0.64,1.05,1.06,2.31,2.67,2.95
			c0.24-0.91,0.56-1.63,0.61-2.37c0.37-4.94,0.75-9.88,0.99-14.83c0.1-2.08,0.87-3.69,2.47-4.96c1.19-0.95,2.3-1,3.49-0.11
			c1.89,1.41,3.13,3.17,3.03,5.67c-0.04,1.11-0.01,2.22-0.01,3.26c1.3,0.58,1.47-0.44,1.92-0.94c0.32-0.35,0.58-0.77,0.88-1.14
			c2.41-2.99,5.28-5.03,9.37-4.96c4.51,0.08,8.56,1.24,11.64,4.68c0.92,1.03,1.58,2.39,2.06,3.71c0.54,1.49-0.16,2.86-1.27,3.89
			c-1.16,1.08-2.64,1.25-4.02,0.57c-1.13-0.56-2.1-1.43-3.12-2.18c-4.98-3.64-7.79-1.88-9.23,3.21c-1.66,5.9,0.21,11.04,4.64,15.22
			c1.62,1.52,2.92,1.21,4.22-0.85c0.6-0.94,1.08-1.98,1.78-2.84c1.36-1.68,3.78-2.02,5.65-0.93c1.38,0.8,2.27,2.43,1.79,4.11
			c-0.66,2.28-1.35,4.67-2.59,6.66c-3.11,5.01-10.71,5.74-15.65,1.64c-3.25-2.7-5.58-6.15-7.4-9.93c-0.55-1.14-0.98-2.33-1.46-3.5
			C483.07,114.48,482.8,114.5,482.52,114.52z"
          />
          <path
            d="M107.65,115.62c0.2,0.49,0.26,0.84,0.46,1.06c4.5,5.09,5.99,11.1,5.76,17.77c-0.14,3.95-1.61,7.1-4.52,9.67
			c-8.19,7.22-20.57,3.18-22.94-7.47c-0.88-3.95-1.23-7.89-0.04-11.85c0.13-0.44,0.02-0.95,0.02-1.78
			c-0.88-0.78-1.94-1.71-2.99-2.65c-5.1-4.55-7.42-10.28-7.24-17.06c0.09-3.38,1.41-6.22,4.05-8.35c5.42-4.39,11.67-6.55,18.62-6.51
			c3.27,0.02,6.28,1.02,8.75,3.34c1.31,1.23,2.32,2.65,2.83,4.37c1.81,6.06,2,11.97-1.71,17.5
			C108.26,114.31,107.95,115.05,107.65,115.62z M102.68,102.59c-0.09-0.38-0.3-1.63-0.66-2.85c-0.47-1.57-1.6-2.24-3.24-2.33
			c-2.8-0.15-5.37,0.66-7.91,1.64c-5.95,2.31-6.52,3.91-4.63,10.38c0.7,2.4,2.35,4.17,4.69,5.17c1.43,0.61,2.89,0.71,3.89-0.71
			c0.97-1.37,2.28-2.11,3.71-2.89C101.44,109.43,102.82,106.83,102.68,102.59z M103.52,125.74c-1.12-2.99-3.57-3.39-5.98-1.2
			c-3.16,2.86-3.94,8.46-1.62,12.15c0.39,0.62,1.11,1.19,1.8,1.42c3.94,1.31,6.96-0.84,7.1-4.96c0.01-0.32,0-0.63,0-0.95"
          />
          <path
            d="M413.81,75.54c-0.84,1-1.58,1.82-2.26,2.7c-1.96,2.53-4.68,3.86-7.67,4.61c-5.41,1.37-10.83-1.51-12.75-6.74
			c-0.54-1.49-0.92-3.09-1.05-4.66c-0.36-4.13,1.64-8.27,6.43-9.33c1.85-0.41,3.72-0.7,5.54-1.04c0.23-0.96-0.46-1.24-1.02-1.42
			c-3-0.96-6.04-1.07-8.8,0.59c-1.98,1.19-3.91,2.1-6.41,1.06c-0.54,4.07-0.46,7.51,0.16,10.99c0.5,2.82-2.37,6.56-5.24,7
			c-0.56,0.09-1.29-0.16-1.78-0.48c-1.69-1.11-2.78-2.61-2.71-4.76c0.03-0.96,0.02-1.92-0.02-2.88c-0.28-6.22-0.54-12.45-0.9-18.67
			c-0.06-1.08-0.34-2.24-0.84-3.2c-1.26-2.41-1.07-4.95-0.98-7.51c0.02-0.47,0.1-0.96,0.24-1.41c0.69-2.3,2.74-4.05,4.77-4.1
			c2.28-0.05,4.07,1.24,4.77,3.93c0.63,2.46,0.96,5.01,1.23,7.55c0.2,1.89,0.19,3.8,0.97,5.99c0.98-0.55,1.76-0.96,2.52-1.42
			c6.28-3.8,15.58-3.08,21.11,1.72c2.34,2.03,4.23,4.47,4.67,7.62c0.4,2.86,1.95,4.55,4.41,5.77c2,0.99,3.93,2.14,5.81,3.35
			c1.34,0.86,2.57,1.89,3.81,2.89c1.2,0.98,1.62,2.34,1.67,3.84c0.05,1.59-0.79,2.81-2.28,3.26c-1.94,0.59-3.71,0.39-5.45-0.84
			c-1.95-1.38-4.05-2.55-6.11-3.78C415.24,75.94,414.75,75.86,413.81,75.54z M401.99,70.47c-0.59,0.04-0.9,0.05-1.21,0.09
			c-0.84,0.11-1.65,0.49-1.49,1.4c0.12,0.67,0.65,1.49,1.23,1.8c1.28,0.66,2.78,0.09,3.68-1.12c0.47-0.62,0.11-1.71-0.64-1.89
			C402.96,70.6,402.34,70.53,401.99,70.47z"
          />
          <path
            d="M394.78,127.6c-0.14,0.94-0.08,1.96-0.45,2.8c-1.05,2.39-2.97,3.23-5.5,2.46c-1.21-0.37-2.34-1.01-3.5-1.55
			c-1.58-0.73-3.15-1.48-4.86-2.29c-0.98,0.79-1.95,1.36-2.64,2.17c-3.27,3.87-7.65,5.28-12.42,5.2
			c-6.54-0.11-12.14-2.48-16.07-8.06c-6.37-9.03-5.17-20.73,0.35-27.82c0.96-1.23,2.17-2.54,3.55-3.1
			c4.58-1.87,9.23-3.56,14.35-3.26c7.68,0.44,15.2,6.65,16.91,14.1c0.65,2.82,1.02,5.65,0.3,8.54c-0.26,1.06-0.2,2.2-0.27,3.11
			c0.9,1.81,2.53,2.23,3.93,2.94c2.45,1.24,4.71,2.7,6.41,4.91L394.78,127.6z M371.45,124.35c-1.77-1.22-3.12-2.05-4.35-3.02
			c-1.32-1.04-2.17-2.39-1.69-4.17c0.49-1.82,2.26-3.05,4.43-2.9c1.24,0.08,2.46,0.57,3.7,0.82c0.72,0.15,1.45,0.18,2.31,0.28
			c1.06-3.91-0.15-6.89-2.6-9.43c-2.11-2.18-4.57-3.48-7.63-2.35c-1.12,1.28-2.01,2.66-3.24,3.61c-1.65,1.28-3.26-0.92-4.83-0.54
			c-1.54,0.82-2.27,2.07-2.62,3.48c-1,3.98-0.94,7.94,0.93,11.69c2.48,4.99,9.02,6.98,13.99,4.29
			C370.35,125.83,370.68,125.21,371.45,124.35z"
          />
          <path
            d="M569.28,118.86c6.15,3.05,11.72,5.8,17.27,8.59c0.84,0.42,1.69,0.92,2.34,1.57c1.01,1.01,2.06,2.13,1.52,3.78
			c-0.54,1.65-2.62,3.22-4.46,3.02c-1.88-0.2-3.77-0.68-5.53-1.36c-1.77-0.69-3.41-1.76-5.07-2.7c-5.17-2.93-10.69-5.07-16.26-7.06
			c-1-0.36-2.12-0.38-3.34-0.59c-0.74,2.76-0.16,5.33-0.39,7.84c-0.15,1.57-0.34,3.17-0.76,4.68c-0.48,1.72-1.85,2.57-3.6,2.66
			c-1.76,0.09-3.34-1.07-4-2.88c-0.27-0.74-0.47-1.54-0.51-2.33c-0.34-7.34-0.79-14.69-0.88-22.03c-0.04-3.65,0.47-7.33,0.98-10.97
			c0.5-3.6,2.32-5.37,5.88-6.01c4.43-0.8,8.87-1.22,13.33-0.15c6.36,1.53,11.11,8.33,10.26,14.83c-0.26,1.97-1.07,3.68-2.5,5.06
			C572.31,116.02,571.04,117.2,569.28,118.86z M555.06,113.05c4.28,0.71,7.63-0.42,10.54-2.99c1.49-1.31,1.63-2.45,0.79-4.28
			c-0.6-1.3-1.68-2-2.95-2.2c-2.69-0.43-5.39-0.46-7.8,1.13C554.9,107.43,554.01,109.99,555.06,113.05z"
          />
          <path
            d="M324.01,127.08c0.94-0.22,1.98-0.47,3.03-0.69c2.49-0.53,4.97-1.18,7.49-1.53c1.22-0.17,2.56,0.04,3.76,0.38
			c1.66,0.46,2.76,1.65,2.87,3.44c0.11,1.73-0.56,3.16-2.18,3.94c-1,0.48-2.06,0.91-3.14,1.09c-3.8,0.65-7.44,1.79-11,3.26
			c-1.16,0.48-2.44,0.8-3.69,0.93c-3.31,0.36-5.97-1.56-6.61-4.86c-0.33-1.71-0.27-3.49-0.35-5.25c-0.17-3.82,0.36-7.66-0.69-11.45
			c-0.24-0.86,0.16-1.87,0.17-2.81c0.03-2.38,0.01-4.76,0.01-7.27c-1.02-0.75-2.13-1.39-3.02-2.26c-2.26-2.21-1.54-5.44,1.45-6.51
			c0.88-0.32,1.89-0.52,2.81-0.45c5.95,0.5,11.78-0.6,17.65-1.2c1.11-0.11,2.23-0.19,3.34-0.16c0.79,0.02,1.6,0.17,2.35,0.43
			c2.74,0.95,4.35,3.43,3.88,5.85c-0.53,2.68-3.07,4.47-6.07,4.24c-0.47-0.04-0.98-0.05-1.41-0.24c-3.84-1.69-7.47-0.37-11.05,0.42
			c-0.73,3.37-0.5,4.07,1.98,4.34c3.49,0.38,7.01,0.39,10.51,0.67c1.73,0.14,3.51,0.32,5.12,0.9c2.2,0.78,3.17,2.6,2.83,4.44
			c-0.35,1.96-1.79,3.4-3.96,3.59c-1.9,0.17-3.83,0.07-5.75,0.05c-2.88-0.03-5.75-0.16-8.63-0.12c-1.57,0.02-2.33,0.82-2.42,2.4
			C323.22,124.07,322.85,125.55,324.01,127.08z"
          />
          <path
            d="M196.18,59.57c-3.49-0.53-6.5-0.89-9.45-1.48c-1.38-0.28-2.75-0.85-3.97-1.56c-1.57-0.93-2.23-2.7-1.81-4.08
			c0.52-1.74,1.8-2.58,3.53-2.78c0.63-0.07,1.27-0.03,1.91,0.03c9.43,0.86,18.82,0.36,28.19-0.85c2.2-0.29,4.35-0.98,6.53-1.46
			c2.49-0.55,4.37,0.45,5.81,2.41c1.05,1.44,0.97,2.96-0.06,4.43c-1.2,1.72-3.01,2.38-4.91,2.68c-3.15,0.51-6.32,0.88-9.49,1.18
			c-2.08,0.19-4.1,0.45-6,1.37c-0.84,1.92-0.05,3.45,0.78,5.08c1.88,0.52,3.49-0.22,5.1-0.84c1.94-0.74,3.86-1.53,5.8-2.25
			c1.58-0.59,3.13-0.4,4.63,0.35c2.11,1.06,3.13,3.32,2.47,5.64c-0.39,1.38-1.49,1.96-2.77,2.38c-3.79,1.25-7.56,2.53-11.33,3.82
			c-0.6,0.2-1.15,0.52-1.87,0.85c-0.78,2.87-0.82,5.44,1.4,7.93c2.03,2.28,1.5,5.3-0.81,7.15c-2.27,1.83-5.41,1.63-7.22-0.75
			c-0.93-1.22-1.54-2.8-1.88-4.3c-0.55-2.48-0.76-5.04-1.12-7.57c-0.02-0.16-0.01-0.32-0.04-0.48c-0.69-4.55-1.94-9.01-2.44-13.6
			C197.06,61.81,196.56,60.78,196.18,59.57z"
          />
          <path
            d="M286.41,109.96c2.32,0.6,4.02,0.97,5.67,1.5c2.58,0.81,5.23,1.51,7.67,2.65c4.67,2.19,7.23,6.7,6.69,11.15
			c-0.65,5.36-3.95,9.26-9.01,10.6c-7.37,1.95-13.48-0.59-18.92-5.39c-1.72-1.52-2.15-3.5-1.6-5.67c0.43-1.69,2.68-3.37,4.74-3.5
			c2.25-0.15,3.88,0.7,5.07,2.81c1.93,3.42,5.56,4.41,8.95,2.59c0.75-0.4,1.42-1.31,1.69-2.13c0.32-1.01-0.45-1.86-1.36-2.27
			c-1.59-0.71-3.22-1.37-4.89-1.84c-2.3-0.66-4.7-0.98-6.96-1.71c-5.49-1.78-7.83-6.03-6.3-11.6c0.5-1.8,1.41-3.59,2.5-5.11
			c2.47-3.44,5.8-5.92,9.72-7.53c4.67-1.91,9.13-1.16,13.22,1.55c1.26,0.84,2.26,2.25,3.04,3.59c1.05,1.81,0.7,3.71-0.56,4.84
			c-1.38,1.23-2.97,0.99-4.51,0.44c-0.74-0.26-1.42-0.73-2.08-1.18c-2.77-1.88-5.44-1.39-8.04,0.31
			C289.15,105.35,287.33,106.82,286.41,109.96z"
          />
          <path
            d="M342.54,52.62c-1.56-0.26-3.14-0.43-4.67-0.8c-1.74-0.41-3.26-1.22-4.29-2.82c-1.47-2.27-0.48-5.01,2.16-5.63
			c0.91-0.21,1.9-0.17,2.85-0.15c8.94,0.26,17.87,0.54,26.8,0.82c0.64,0.02,1.28,0.02,1.91,0.12c2.51,0.4,4.07,1.84,4.37,4.36
			c0.1,0.83-0.26,1.96-0.82,2.56c-1.33,1.44-3.04,2.2-5.13,2.07c-3.03-0.18-6.06-0.21-9.1-0.22c-0.93,0-1.87,0.25-2.78,0.48
			c-0.21,0.05-0.44,0.49-0.44,0.76c0,0.27,0.2,0.67,0.43,0.78c0.56,0.28,1.16,0.49,1.77,0.6c3.45,0.65,6.92,1.2,10.35,1.93
			c1.38,0.3,2.76,0.84,4.01,1.51c1.54,0.83,2.18,2.3,1.95,4.04c-0.23,1.74-1.69,3.21-3.53,3.41c-1.09,0.11-2.22-0.04-3.31-0.21
			c-2.05-0.33-4.07-0.82-6.12-1.15c-1.08-0.17-2.19-0.16-3.04-0.22c-1.38,0.96-1.24,2.13-1.06,3.21c0.32,1.88,0.85,3.73,1.18,5.61
			c0.46,2.68-0.07,5.08-2.27,6.91c-1.1,0.92-2.35,1.08-3.56,0.42c-1.89-1.04-3.24-2.51-3.49-4.83c-0.3-2.86-0.74-5.7-1.11-8.55
			c-0.31-2.4-1.18-4.28-3.95-4.65c-0.76-0.1-1.54-0.46-2.2-0.87c-2.64-1.66-2.76-5.01-0.2-6.75c1.12-0.76,2.4-1.29,3.6-1.93
			C342.74,53.18,342.64,52.9,342.54,52.62z"
          />
          <path
            d="M432.79,121.82c3.73-0.91,7.13-1.77,10.55-2.56c1.08-0.25,2.2-0.41,3.3-0.41c2.36-0.01,3.89,1.43,4.77,3.42
			c0.33,0.74,0.19,1.97-0.22,2.7c-0.89,1.56-2.29,2.69-4.14,2.99c-4.9,0.81-9.68,2.1-14.36,3.77c-0.45,0.16-0.93,0.23-1.41,0.29
			c-4.51,0.59-7.47-1.49-8.32-5.92c-0.21-1.09-0.24-2.22-0.25-3.34c-0.02-4.16-0.01-8.31,0.01-12.47c0.01-2.56-0.06-5.06-0.92-7.56
			c-0.8-2.32,0.19-4.09,2.49-4.94c1.17-0.44,2.48-0.67,3.73-0.69c4.79-0.09,9.59-0.08,14.39-0.06c0.95,0,1.93,0.1,2.84,0.36
			c1.98,0.56,3.25,2.07,3.28,3.68c0.03,1.77-1.3,3.88-3.07,4.47c-1.03,0.35-2.18,0.45-3.28,0.48c-2.4,0.07-4.8-0.01-7.19,0.03
			c-0.78,0.01-1.57,0.18-2.33,0.37c-0.22,0.06-0.46,0.46-0.48,0.73c-0.02,0.26,0.17,0.72,0.37,0.79c0.74,0.23,1.52,0.46,2.29,0.47
			c2.88,0.05,5.76,0.1,8.63,0c2.8-0.09,4.43,1.45,5.7,3.65c0.56,0.96,0.34,1.85-0.36,2.63c-1.56,1.76-3.5,2.72-5.89,2.73
			c-2.88,0.02-5.76,0.01-8.63,0.15C431.86,117.7,431.48,118.75,432.79,121.82z"
          />
          <path
            d="M538.69,102.51c-1.14,2.95-3.28,4.48-6.56,4.39c-1.6-0.04-3.2-0.08-4.8-0.04c-0.55,0.01-1.09,0.27-2.24,0.58
			c0.94,0.48,1.29,0.79,1.66,0.82c4.11,0.36,8.24,0.64,12.35,1.03c2.67,0.25,4.25,2.6,3.29,5.11c-0.49,1.28-1.42,2.39-2.28,3.76
			c1.96,1.14,3.56,2.57,3.86,5.07c0.27,2.25-0.19,3.24-2.5,3.84c-1.38,0.36-2.83,0.65-4.25,0.64c-3.91-0.05-7.62,0.75-11.21,2.25
			c-0.44,0.18-0.91,0.32-1.36,0.46c-4.01,1.22-7.36-0.67-8.22-4.83c-0.39-1.86-0.45-3.81-0.54-5.72c-0.2-4.3-0.32-8.61-0.48-13.03
			c-1-0.69-1.9-1.31-2.79-1.94c-1.4-1.01-2.19-2.37-1.88-4.12c0.35-1.96,1.74-3.04,3.58-3.41c1.24-0.25,2.54-0.35,3.81-0.29
			c4.95,0.23,9.9,0.5,14.84,0.86C535.72,98.14,536.55,98.89,538.69,102.51z M533.85,118.33c-0.01-0.3-0.03-0.6-0.04-0.9
			c-2.19,0-4.38-0.04-6.57,0.02c-0.95,0.03-1.71,0.99-1.29,1.53c0.31,0.4,1.03,0.81,1.48,0.73
			C529.58,119.35,531.71,118.81,533.85,118.33z"
          />
          <path
            d="M135.19,110.32c2.78,0.36,4.81,0.62,6.84,0.9c2.87,0.4,5.61,1.22,8.14,2.65c4.25,2.41,6.38,6.1,6.51,10.98
			c0.09,3.28-1.16,5.95-3.76,7.88c-1.26,0.93-2.71,1.73-4.2,2.21c-7.05,2.29-13.56,1.17-19.34-3.55c-2.32-1.89-2.98-4.45-1.91-6.8
			c1.13-2.5,3.57-3.75,6.28-3.19c1.48,0.31,2.82,1,3.26,2.51c0.96,3.26,3.41,3.42,6.09,3.11c1.09-0.13,2.2-0.42,3.21-0.85
			c1.47-0.62,1.78-2.49,0.54-3.56c-0.94-0.81-2.07-1.6-3.24-1.92c-1.98-0.54-4.06-0.77-6.12-1.02c-1.58-0.19-3.2-0.08-4.77-0.33
			c-5.43-0.86-8.19-5.28-6.59-10.57c0.65-2.15,1.65-4.12,3.21-5.82c2.42-2.63,5.13-4.79,8.49-6.07c2.13-0.81,4.34-1.13,6.58-0.59
			c1.93,0.47,3.53,1.46,4.45,3.33c1.1,2.25,0.1,4.65-2.32,5.31c-0.9,0.25-1.89,0.31-2.84,0.26
			C140.15,105.04,137.7,106.75,135.19,110.32z"
          />
          <path
            d="M289.51,60.15c-2.22-1.64-3.69-1.41-5.1-1.4c-2.07,0.02-4.15,0.23-6.21,0.15c-1.07-0.04-2.23-0.31-3.16-0.83
			c-1.99-1.11-2.37-3.54-1.01-5.36c1-1.34,2.38-2.18,3.97-2.48c3.28-0.62,6.55-1.06,9.96-0.55c5.91,0.88,9.33,4.39,11.29,9.72
			c0.16,0.45,0.36,0.91,0.39,1.38c0.14,2.25,1.38,3.79,3.02,5.19c2.09,1.8,3.45,4.17,4.64,6.61c0.83,1.69-0.41,4.95-2.13,5.79
			c-0.52,0.25-1.28,0.32-1.82,0.15c-1.5-0.49-2.93-1.15-4.6-1.83c-0.66,0.6-1.34,1.23-2.03,1.85c-6.33,5.79-14.88,4.42-18.82-3.1
			c-1.03-1.96-1.91-4.05-2.49-6.18c-0.93-3.43,0.31-6.03,3.58-7.35c1.89-0.76,3.99-1.03,6.01-1.4
			C286.18,60.3,287.42,60.31,289.51,60.15z M285.67,70.05c-0.23,1.56,0.6,2.39,1.93,2.78c0.97,0.29,1.88-0.02,2.51-0.84
			c0.27-0.35,0.52-0.87,0.46-1.26c-0.06-0.37-0.49-0.89-0.85-0.97C288.37,69.44,286.97,69.18,285.67,70.05z"
          />
          <path
            d="M394.87,127.75c-1.9-6.5-2.56-13.14-2.15-19.87c0.14-2.37,0.57-4.74,1.09-7.06c0.23-1.04,0.89-2.05,1.56-2.91
			c0.93-1.17,2.2-1.86,3.78-1.54c1.64,0.33,3.18,1.91,3.31,3.68c0.09,1.25-0.24,2.52-0.35,3.78c-0.57,6.21-0.93,12.43,0.64,18.55
			c0.47,1.82,1.35,3.56,2.22,5.25c0.54,1.04,2.07,0.98,2.85,0.04c1.82-2.2,2.38-4.83,2.5-7.59c0.22-5.27-0.04-10.53-0.79-15.75
			c-0.16-1.1-0.43-2.22-0.38-3.31c0.15-3.02,3.42-4.72,5.91-3.06c0.89,0.59,1.69,1.47,2.23,2.39c0.56,0.94,0.92,2.06,1.08,3.15
			c0.97,6.64,1.8,13.28,0.68,20c-0.65,3.88-2.28,7.32-4.64,10.38c-3.95,5.11-10.37,5.45-15.04,0.97c-2.12-2.03-3.39-4.61-4.61-7.22
			C394.78,127.6,394.87,127.75,394.87,127.75z"
          />
          <path
            d="M480.15,52.75c1.66,0.16,3.07,0.3,4.47,0.44c2.72,0.27,5.35,0.82,7.79,2.13c2.55,1.37,4.16,3.36,4.7,6.25
			c0.95,5.09-0.33,9.82-2.38,14.38c-0.9,2-2.94,2.67-4.82,2.02c-1.82-0.63-3.18-2.49-2.94-4.45c0.28-2.21,0.83-4.38,1.25-6.57
			c0.67-3.52-0.18-4.61-3.85-4.8c-0.76-0.04-1.53,0.24-2.53,0.41c-0.17,0.99-0.47,1.89-0.44,2.79c0.08,2.87,0.33,5.73,0.41,8.6
			c0.03,1.08,0.05,2.33-0.44,3.22c-0.83,1.5-1.94,3.27-3.86,3.17c-2.06-0.11-3.66-1.8-4.01-3.62c-0.86-4.56-2.64-8.84-3.6-13.33
			c-0.32-1.47-0.06-3.24,0.48-4.66c0.83-2.21,0.88-4.24,0.31-6.51c-0.77-3.08-1.16-6.26-1.78-9.38c-0.45-2.25-0.43-4.43,0.61-6.54
			c0.54-1.09,1.4-1.74,2.61-1.82c1.4-0.1,2.56,0.48,3.18,1.71c0.56,1.13,0.91,2.37,1.26,3.6C477.74,43.92,478.86,48.06,480.15,52.75
			z"
          />
          <path
            d="M455.28,71.43c-1,0.12-2-0.22-2.83-0.78c-0.69-0.47-1.47-0.81-2.23-1.21c-6.54-3.5-7.45-10.17-2.13-15.42
			c2.18-2.16,4.72-3.81,7.57-4.96c2.7-1.08,5.39-0.93,7.71,0.89c0.95,0.75,1.77,1.87,2.24,2.99c0.94,2.23-0.7,4.61-3.14,4.81
			c-0.47,0.04-0.96-0.03-1.43-0.12c-2.9-0.59-4.87,0.81-6.52,2.78c0.02,1.25,0.89,1.42,1.62,1.64c1.68,0.5,3.43,0.8,5.07,1.41
			c3.28,1.23,5.74,3.33,6.95,6.78c2.32,6.61-1.53,12.43-8.54,12.87c-3.98,0.25-7.03-1.65-9.94-4.02c-1.82-1.48-2.36-3.38-2.04-5.55
			c0.1-0.69,0.61-1.62,1.19-1.89c1.59-0.75,3.32-1.15,4.95,0.11c1.1,0.59,2.15,1.24,3.25,1.79c0.69,0.35,1.97,0.44,2.42-0.51
			C458.19,71.75,456.82,71.24,455.28,71.43z"
          />
          <path
            d="M256.9,76c0,1.75,0.13,3.51-0.04,5.25c-0.16,1.65-0.88,3.14-2.69,3.56c-1.72,0.41-3.28-0.25-4.2-1.64
			c-0.85-1.29-1.65-2.85-1.76-4.34c-0.26-3.48-0.26-7.01-0.05-10.5c0.17-2.74,1.26-5.26,3.02-7.43c4.04-4.99,10.7-5.19,14.96-0.37
			c1.04,1.18,1.95,2.55,2.63,3.97c1.8,3.77,2.77,7.8,3.21,11.95c0.23,2.15-0.16,4.15-2.04,5.56c-2.15,1.61-4.37,1.25-5.62-1.12
			c-0.65-1.24-0.94-2.68-1.28-4.06c-0.54-2.16-0.89-4.37-1.5-6.51c-0.3-1.04-0.95-1.99-1.55-2.92c-0.43-0.67-1.72-0.56-2.01,0.18
			c-0.4,1.02-0.83,2.09-0.92,3.16c-0.15,1.74-0.04,3.5-0.04,5.26C256.98,76,256.94,76,256.9,76z"
          />
          <path
            d="M308.03,68.69c-0.27-7.96-0.54-15.91-0.79-23.87c-0.04-1.27-0.11-2.59,0.15-3.81c0.26-1.2,0.73-2.48,1.48-3.43
			c1.68-2.14,4.6-1.99,6.05,0.3c0.66,1.03,1.03,2.33,1.22,3.55c0.22,1.4,0.06,2.86,0.12,4.29c0.32,7,0.61,14,1.02,20.99
			c0.07,1.23,0.51,2.49,0.97,3.65c1.48,3.66,0.17,6.91-1.57,9.99c-1.11,1.96-3.56,2.17-5.43,0.76c-2.05-1.55-2.97-3.66-2.95-6.22
			c0.02-2.07,0-4.13,0-6.2C308.22,68.69,308.13,68.69,308.03,68.69z"
          />
          <path
            d="M240.5,86.18c-3.05-1.63-4.53-3.86-4.82-6.99c-0.22-2.38-0.8-4.73-0.95-7.11c-0.08-1.38,0.11-2.91,0.65-4.17
			c1.12-2.63,4.57-2.88,6.24-0.53c0.54,0.76,0.98,1.67,1.19,2.58c0.71,2.95,1.33,5.91,1.9,8.89
			C245.39,82.31,243.96,84.68,240.5,86.18z"
          />
          <path
            d="M242.56,57.12c-0.03,3.07-3.05,5.83-5.93,5.53c-3.17-0.32-5.42-3.2-4.9-6.35c0.15-0.93,0.42-1.86,0.79-2.73
			c1.49-3.51,5.42-4.24,8.12-1.53C242.05,53.48,242.64,55.24,242.56,57.12z"
          />
          <path
            d="M443.77,74.2c-0.06,2.6-2.29,4.82-4.89,4.87c-3.31,0.06-5.96-2.38-5.93-5.48c0.03-3.05,2.54-6.03,5.06-5.98
			C440.78,67.65,443.84,71.14,443.77,74.2z"
          />
        </g>
      </g>
    </svg>
  )
}

export default React.memo(SequencerTitle)

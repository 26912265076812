import { Sequence } from './types'

export enum Category {
  DR = 'DR',
  BS = 'BS',
  MEL = 'MEL',
  FX = 'FX',
  AMBIENT = 'AMBIENT',
  TRADITIONAL = 'TRADITIONAL',
}

export enum Sound {
  DR_1 = 'DR_1',
  DR_2 = 'DR_2',
  DR_3 = 'DR_3',
  DR_4 = 'DR_4',
  DR_5 = 'DR_5',
  DR_6 = 'DR_6',
  DR_7 = 'DR_7',
  DR_8 = 'DR_8',
  DR_9 = 'DR_9',
  DR_10 = 'DR_10',
  DR_11 = 'DR_11',
  DR_12 = 'DR_12',
  BS_1 = 'BS_1',
  BS_2 = 'BS_2',
  BS_3 = 'BS_3',
  BS_4 = 'BS_4',
  BS_5 = 'BS_5',
  BS_6 = 'BS_6',
  BS_7 = 'BS_7',
  BS_8 = 'BS_8',
  BS_9 = 'BS_9',
  BS_10 = 'BS_10',
  BS_11 = 'BS_11',
  BS_12 = 'BS_12',
  MEL_1 = 'MEL_1',
  MEL_2 = 'MEL_2',
  MEL_3 = 'MEL_3',
  MEL_4 = 'MEL_4',
  MEL_5 = 'MEL_5',
  MEL_6 = 'MEL_6',
  MEL_7 = 'MEL_7',
  MEL_8 = 'MEL_8',
  MEL_9 = 'MEL_9',
  MEL_10 = 'MEL_10',
  MEL_11 = 'MEL_11',
  MEL_12 = 'MEL_12',
  FX_1 = 'FX_1',
  FX_2 = 'FX_2',
  FX_3 = 'FX_3',
  FX_4 = 'FX_4',
  FX_5 = 'FX_5',
  FX_6 = 'FX_6',
  FX_7 = 'FX_7',
  FX_8 = 'FX_8',
  FX_9 = 'FX_9',
  FX_10 = 'FX_10',
  FX_11 = 'FX_11',
  FX_12 = 'FX_12',
  AMBIENT_1 = 'AMBIENT_1',
  AMBIENT_2 = 'AMBIENT_2',
  AMBIENT_3 = 'AMBIENT_3',
  AMBIENT_4 = 'AMBIENT_4',
  AMBIENT_5 = 'AMBIENT_5',
  AMBIENT_6 = 'AMBIENT_6',
  AMBIENT_7 = 'AMBIENT_7',
  AMBIENT_8 = 'AMBIENT_8',
  AMBIENT_9 = 'AMBIENT_9',
  AMBIENT_10 = 'AMBIENT_10',
  AMBIENT_11 = 'AMBIENT_11',
  AMBIENT_12 = 'AMBIENT_12',
  TRADITIONAL_1 = 'TRADITIONAL_1',
  TRADITIONAL_2 = 'TRADITIONAL_2',
  TRADITIONAL_3 = 'TRADITIONAL_3',
  TRADITIONAL_4 = 'TRADITIONAL_4',
  TRADITIONAL_5 = 'TRADITIONAL_5',
  TRADITIONAL_6 = 'TRADITIONAL_6',
  TRADITIONAL_7 = 'TRADITIONAL_7',
  TRADITIONAL_8 = 'TRADITIONAL_8',
  TRADITIONAL_9 = 'TRADITIONAL_9',
  TRADITIONAL_10 = 'TRADITIONAL_10',
  TRADITIONAL_11 = 'TRADITIONAL_11',
  TRADITIONAL_12 = 'TRADITIONAL_12',
  TRADITIONAL_13 = 'TRADITIONAL_13',
}

export const MATERIALS_OFFSET = {
  [Category.DR]: 0,
  [Category.BS]: 12,
  [Category.MEL]: 24,
  [Category.FX]: 36,
  [Category.AMBIENT]: 48,
  [Category.TRADITIONAL]: 60,
}

export const STEPS = 8

export const LAYERS = 6

export const INITIAL_SEQUENCE: Sequence = [
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
  [-1, -1, -1, -1, -1, -1],
]

export const STRINGIFIED_EMPTY_SEQUENCE =
  '-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1-1'

export const HOWTO_URL = 'https://youtu.be/gKO0HJSOZnI'

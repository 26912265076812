import React from 'react'
import styles from './SequencerBolt.module.scss'

const SequencerBolt: React.FC = () => (
  <svg
    className={styles.SequencerBolt}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54.33 53.21"
  >
    <g>
      <path
        fill="#949494"
        d="M40.62,15.91c-1.45-2.52-3.66-4.6-6.35-5.72c-1.06-0.87-2.27-1.54-3.57-1.91c-1.6-0.45-3.34-0.42-5,0
		c-3.63-0.65-7.61,0.32-9.98,3.03c-0.81,0.15-1.58,0.54-2.27,1.1c-2.57,2.09-5.2,6.49-5.1,9.9c0.02,0.63,0.19,1.21,0.47,1.72
		c-0.78,1.69-1.16,3.64-0.98,5.69c0.32,3.54,2.55,7.57,5.59,9.72c0.56,0.72,1.16,1.41,1.82,2.06c7.39,7.25,18.64,4.39,25.18-2.48
		c0.8-0.84,1.28-1.75,1.52-2.68c0.16-0.25,0.33-0.5,0.48-0.77C48.34,31.36,47.08,19.17,40.62,15.91z"
      />
      <path
        d="M43.16,11.01c-3.96-5.03-8.73-7.26-15.12-7.6C15.12,2.73,3.79,12.55,3.79,25.77c0,0.1,0.02,0.19,0.03,0.28
		c0.06,11.72,8.45,23.43,20.87,24.02c11.95,0.57,24.61-7.88,25.28-20.47C50.33,22.74,47.33,16.3,43.16,11.01z M43.89,30.04
		c-0.97,8.94-10.71,14.65-19.2,14.03C14.72,43.34,9.2,33.19,9.88,24.1c0-0.01,0-0.01,0-0.02c0.9-9.16,9.06-15.19,18.16-14.66
		C37.76,9.98,44.91,20.62,43.89,30.04z"
      />
      <path
        d="M37.72,18.11c-0.11-0.36-0.48-0.73-0.69-0.99c-0.69-0.84-1.52-1.56-2.44-2.13c-0.86-0.53-1.81-0.76-2.65-0.07
		c-0.95,0.77-1.78,1.75-2.64,2.63c-3.47,3.58-6.79,7.31-9.93,11.19c-0.85,1.05-1.7,2.12-2.52,3.19c-0.42,0.55-0.93,1.11-1.33,1.72
		c-0.02,0.01-0.04,0.02-0.05,0.03c-0.25,0.25-0.43,0.55-0.56,0.87c-0.08,0.08-0.14,0.18-0.16,0.32c-0.09,0.97,0.49,1.76,1.16,2.4
		c0.87,0.82,1.83,1.58,2.77,2.32c0.79,0.62,1.56,0.62,2.31-0.06c0.9-0.81,1.72-1.73,2.54-2.61c3.33-3.58,6.38-7.42,9.3-11.33
		c1.66-2.22,3.29-4.47,4.91-6.71c0.08-0.11,0.1-0.22,0.08-0.32C37.86,18.4,37.84,18.25,37.72,18.11z"
      />
    </g>
  </svg>
)

export default React.memo(SequencerBolt)

import React from 'react'

import styles from './ClearButton.module.scss'
import clsx from 'clsx'

interface ClearButtonProps {
  onClick: () => void
}

const ClearButton: React.FC<ClearButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.ClearButton} onClick={onClick}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="22.37 11.32 89.60 101.33"
      >
        <g>
          <path
            fill="#ffffff"
            d="M99.87,82.94c-6.22-7.96-12.44-15.93-18.66-23.89c0.13-0.16,0.26-0.33,0.39-0.51
		c5.37-7.86,10.55-15.85,15.53-23.97c0.71-1.15,0.97-2.29,0.91-3.34c0.91-0.71,1.7-1.69,2.04-2.82c0.48-0.89,0.73-1.83,0.73-2.85
		c0.05-0.41,0.02-0.81-0.04-1.21c-0.01-0.13-0.01-0.26-0.03-0.4c-0.15-1.09-0.27-1.63-0.85-2.71c-0.64-1.19-1.74-2.33-2.94-2.97
		c-0.94-0.5-1.97-0.78-2.99-0.8c-0.17-0.12-0.32-0.25-0.5-0.37c-6.56-4.29-11.74,2.34-15.22,6.99c-4.28,5.71-8.29,11.6-12.09,17.63
		c-0.06,0.06-0.13,0.11-0.19,0.17c-0.37,0.21-0.73,0.44-1.06,0.71c-6.47-7.28-12.86-14.92-20.06-21.29
		c-3.72-3.29-8.6-5.55-13.59-3.88c-4.58,1.54-7.42,6.04-6.71,10.83c0.77,5.22,5.09,10.16,8.19,14.24
		c3.39,4.45,7.07,8.58,11.03,12.53c2.72,2.71,4.09,5.6,5.54,8.66c-0.24,0.25-0.48,0.53-0.69,0.84c-4.3,6.16-9.84,12.89-11.74,20.4
		c-1.18,1.19-1.92,2.87-1.96,4.58c-2.86,1.29-4.04,4.77-2.93,7.73c-0.44,0.82-0.69,1.76-0.66,2.76c0.25,8.36,7.91,10.6,14.95,8.84
		c2.04-0.51,3.29-1.74,3.87-3.23c1.07-0.58,1.87-1.52,2.37-2.63c2.1-0.78,3.57-2.93,3.85-5.24c0.36-0.38,0.69-0.82,0.97-1.35
		c2.6-4.91,5.2-9.81,7.8-14.72c0.45-0.85,1.01-1.77,1.57-2.74c5.43,6.4,10.97,12.72,17.07,18.5c4.68,4.43,15.52,14.69,22.26,7.97
		C112.81,98.6,104.06,88.3,99.87,82.94z"
          />

          <path
            d="M46.86,64.24c-7.37-9.88-14.53-19.27-21.44-28.84c-4.33-6-3.94-13.15,0.52-17.33c6.47-6.08,11.64-6.59,18.62-1.07
			c7.44,5.89,14.52,12.23,21.53,18.18c1.9-2.64,5.16-7.8,9.07-12.42c3.07-3.61,6.67-6.92,10.53-9.65c4.25-3,7.92-2.09,11.53,1.77
			c6.73,7.18,7.31,11.87,1.83,21.06c-4.67,7.84-9.78,15.43-14.67,23.1c8.13,10.39,16.54,20.87,24.64,31.58
			c5.11,6.76,3.43,16.05-3.74,19.18c-2.98,1.3-7.28,1.41-10.33,0.29c-3.73-1.37-6.94-4.41-10.06-7.1c-3.27-2.82-6.13-6.1-9.21-9.15
			c-3.07-3.03-6.18-6.02-9.02-8.78c-4.35,6.96-8.69,14.14-13.27,21.16c-3.83,5.87-9.28,7.55-16.06,5.77
			c-8.46-2.23-12.22-8.22-8.49-16.18c4.5-9.6,10.18-18.64,15.4-27.9C44.98,66.62,45.97,65.48,46.86,64.24z M97.41,26.3
			c0.35-0.74,0.28-1.6-0.21-2.26c-1.71-2.32-3.23-4.8-5.42-6.33c-1.08-0.75-4.71,0.5-5.95,1.91c-5.34,6.09-10.5,12.37-15.18,18.96
			c-5.08,7.15-6.35,7.3-11.97,0.93C53.2,33.29,47.41,27.3,41.44,21.53c-2.13-2.06-4.77-4.1-8.44-1.85
			c-5.53,3.37-6.83,7.53-3.03,12.81c5.83,8.1,11.98,15.96,17.84,24.03c5.44,7.5,5.05,7.37,0.12,15.18
			c-5.13,8.13-9.71,16.63-14.29,25.1c-1.52,2.83-2.24,6.06,1.17,8.46c4.64,3.26,10.5,2.91,13.58-1.77
			c4.45-6.76,8.34-13.92,12.06-21.12c3.08-5.98,5.03-6.56,9.86-1.81c7.11,6.99,13.99,14.22,21.3,20.99c5.4,5,9.48,5.43,12.67,2.34
			c2.81-2.72,2.41-7.34-1.69-12.63c-7.02-9.06-14.19-18.02-21.55-26.8c-2.9-3.46-3.45-6.38-0.74-10.21
			c4.51-6.37,8.77-12.92,12.93-19.54C94.79,32.24,95.9,29.5,97.41,26.3z"
          />
          <path
            d="M61.66,60.85c-8.43-9.75-16.8-19.44-25.17-29.13c-0.76-0.88-1.93-1.67-2.16-2.68c-0.3-1.29-0.31-3.2,0.44-4.01
			c0.57-0.61,3-0.43,3.81,0.29c3.32,2.98,6.53,6.12,9.4,9.52c5.78,6.84,11.31,13.9,17.4,21.42c3.22-4.61,5.99-8.51,8.69-12.45
			c3.95-5.76,7.69-11.66,11.89-17.22c0.57-0.75,1.73-1.05,2.96-1.31c1.16-0.24,2.24,0.65,2.25,1.83c0.01,1.12-0.04,2.19-0.55,2.97
			c-6.76,10.27-13.77,20.37-20.89,30.79c4.47,4.85,8.94,9.53,13.21,14.38c5.38,6.12,10.61,12.37,15.89,18.58
			c0.74,0.87,1.78,1.8,1.92,2.81c0.19,1.33,0.1,3.31-0.72,3.99c-0.71,0.59-3.03,0.23-3.89-0.54c-2.59-2.32-4.89-4.98-7.16-7.64
			c-7.34-8.59-14.61-17.23-22.28-26.3c-1.28,1.45-2.35,2.39-3.09,3.54c-5.96,9.21-11.75,18.53-17.86,27.64
			c-0.64,0.95-1.72,1.6-2.87,2.21c-1.09,0.58-2.43-0.06-2.63-1.28c-0.26-1.5-0.45-2.97,0.14-3.96C47.2,83.1,54.42,72.13,61.66,60.85
			z"
          />
        </g>
      </svg>
      <div className={styles.label}>CLEAR</div>
    </button>
  )
}

export default ClearButton
